<template>
  <div>
    <div class="steps">
      <div
        v-for="(step, index) in steps"
        :key="step"
        :class="{ done: currentStep > index }"
        class="step"
      />
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    steps: { type: Number, default: 0 },
    currentStep: { type: Number, default: 0 },
  },
};
</script>
<style scoped>
.steps {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
.step {
  width: 100%;
  height: 4px;
  background: var(--box-up);
  border-radius: 30px;
}
.step:not(:last-child) {
  margin-right: 10px;
}
.step.done {
  background: var(--default);
}
.none-steps {
  width: 0;
  height: 0;
  opacity: 0;
}
</style>
