<template>
  <div class="badge" :class="`${type} ${absolute ? 'absolute' : ''}`">
    <div v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>
    {{ name }}
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    type: { type: String, default: 'neutral' },
    absolute: { type: Boolean, default: true },
  },
};
</script>
<style scoped>
.badge {
  border-radius: 10px;
  padding: 5px 10px;
  line-height: 15px;
  font-size: 12px;
  text-align: center;
  font-weight: 900;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
}
.badge i {
  margin-right: 10px;
  font-size: 12px;
}
.badge i {
  margin-right: 10px;
  font-size: 12px;
}
.badge.absolute {
  position: absolute;
  right: 20px;
}
.badge.pro {
  background: linear-gradient(296deg, var(--default) 0%, #000033 100%);
  color: #fff;
}
.badge.orange {
  background: rgba(255, 146, 45, 0.15);
  color: rgba(255, 146, 45, 0.75);
  font-weight: 400;
}
.badge.exclamation {
  background: rgba(255, 241, 173, 0.15);
  color: rgba(255, 241, 173, 0.75);
  text-transform: none;
  font-weight: normal;
}
.badge.default {
  background: var(--box-up);
  color: var(--label-primary);
  text-transform: none;
  font-weight: normal;
}
.badge.default.disable {
  color: #aaaaab70 !important;
  background: #292e33;
}
.badge.yellow {
  background: rgba(26, 29, 31, 0.5);
  color: rgba(255, 241, 173, 0.65);
}
.badge.reach {
  background: transparent;
  color: var(--default);
  border: 1px solid #2b3154;
  text-transform: none;
  font-weight: normal;
}
.badge.neutral {
  background: #1a1d1f5e;
  color: #6f767e;
}
.badge.neutral_2 {
  border-color: #6f767e8c;
  color: #6f767e;
  background-color: transparent !important;
}
.badge.success {
  background: #374d43;
  color: #87e39d;
}
.badge.danger {
  background: #5e4148;
  color: #db5266db;
}
.badge.gold {
  background: #ffcc991a;
  color: #ffcc99e0;
  font-weight: 400;
  text-transform: initial;
}
.badge.purple {
  background: #2e324d;
  color: #6465ff;
  font-weight: normal;
}

@media screen and (max-width: 991px) {
  .badge {
    line-height: 12px;
    font-size: 10px;
  }
}
</style>
