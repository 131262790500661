<template>
  <div>
    <base-card>
      <base-label text="Determine o percentual do cashback" />
      <base-input
        label="Percentual de cashback"
        class="w-100 mb_0"
        v-model="cashbackValues.percentage"
        :thousandSeparator="false"
        :money-mask="StringLanguage.percentIntegerConfigs"
        :max="5"
      />
    </base-card>
    <base-card>
      <base-label text="Defina o valor do pedido mínimo" />
      <base-input
        label="Pedido mínimo"
        class="w-100 mb_0"
        v-model="cashbackValues.min_order"
        :money-mask="StringLanguage.currencyConfigs"
      />
      <!-- <base-checkbox
            :checked="noMinimumOrder"
            v-model="noMinimumOrder"
            class="mt_20"
          >
            <p class="text-checkbox">Sem pedido mínimo</p>
          </base-checkbox> -->
    </base-card>
    <base-card>
      <base-label text="Defina a expiração" />
      <base-input
        class="w-100 mb_0"
        v-model="cashbackValues.expiration_days"
        :thousandSeparator="false"
        :money-mask="StringLanguage.timeConfigs.days"
      />
    </base-card>
    <base-card>
      <base-label text="Determine o valor máximo em cashback" />
      <base-input
        class="w-100 mb_0"
        v-model="cashbackValues.max_gain"
        :money-mask="StringLanguage.currencyConfigs"
      />
    </base-card>
  </div>
</template>

<script>
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseCard from '@/components/Card';
import BaseCheckbox from '@/components/Checkbox';

export default {
  components: {
    BaseLabel,
    BaseInput,
    BaseCard,
    BaseCheckbox
  },
  props: {
    cashback: {
      type: Object,
      default: () => ({
        percentage: null,
        expiration_days: null,
        max_gain: null,
        min_order: null,
      }),
    },
  },
  data() {
      return {
          cashbackValues: JSON.parse(JSON.stringify(this.cashback))
      }
  },
  methods: {
      emitChange() {   
        this.$emit('changed', {
            percentage: this.cashbackValues.percentage,
            expiration_days: this.cashbackValues.expiration_days,
            max_gain: this.cashbackValues.max_gain,
            min_order: this.cashbackValues.min_order
        });
      }
  },
  watch: {
      cashbackValues: {
          deep: true,
          handler(newValue, prevValue) {
              if(JSON.stringify(newValue) != JSON.stringify(this.cashback)) {
                  this.emitChange();
              }
          }
      }
  }
};
</script>

<style>
</style>