<!--

== Base Checkbox

Props:

    > label
    Texto que vai ao lado do checkbox. E só aparece, se o slot estiver vazio.


Obs:

- O slot incluso serve para incluir elementos, que ao clicados irão definir este checkbox como "checked". Caso não haja um conteúdo no slot, é necessário informar uma label.


-->

<template>
  <label
    :for="!noCheck ? id : ''"
    class="checkbox-box"
    :class="{ center: center, disabled: disabled, notclickable: notclickable }"
  >
    <input
      type="checkbox"
      :id="id"
      v-bind="$attrs"
      v-model="insideValue"
      v-on="inputListeners"
      :checked="insideValue"
    />
    <span class="checkbox">
      <i class="fal fa-check"></i>
    </span>

    <div class="checkbox-content" :class="{ hover: hover }">
      <p v-if="!$slots.default" class="checkbox-label">
        {{ label }}
      </p>

      <!-- Conteúdo para ser clicado (Não obrigatório) -->
      <slot></slot>
    </div>
  </label>
</template>

<script>
import { baseEvents } from '@/mixins/baseEvents.js';

const checkBoxObj = {
  mixins: [baseEvents],
  inheritAttrs: false,
  props: {
    label: { type: String },
    defaultValue: { default: true },
    checked: { default: false },
    center: { type: Boolean, default: false },
    noCheck: { type: Boolean, default: false },
    hover: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    notclickable: { type: Boolean, default: false },
  },
  data() {
    return {
      id: '',
      originalValue: this.$props.defaultValue || true,
      insideValue: !!this.$props.checked || false,
    };
  },
  created() {
    // Gerando ID único
    this.id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  },
  watch: {
    insideValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue)
          this.$emit('input', newValue ? this.originalValue : false);
      },
      deep: true,
    },
    checked: {
      handler(newValue) {
        this.insideValue = !!newValue;
      },
      deep: true,
    },
  },
};

export default checkBoxObj;
</script>

<style scoped>
input[type='checkbox'] {
  display: none;
}

.checkbox-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-box.center {
  justify-content: center;
}

.checkbox-box.disabled {
  pointer-events: none;
}

.checkbox-box.notclickable {
  opacity: 0.5;
}

.checkbox {
  color: transparent;
  font-size: 9px;
  border: 1px solid var(--checkbox);
  height: 17px;
  width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 5px;
}

input:checked + .checkbox {
  color: var(--default);
  border-color: var(--default);
}

.checkbox-content {
  font-size: 12px;
  color: var(--label-primary);
}

.checkbox-box.center .checkbox-content {
  flex: 0;
  white-space: nowrap;
}

.checkbox-content.hover p {
  border-bottom: 1px solid transparent;
  padding-bottom: 2px;
  margin-top: 2px;
}
.checkbox-content.hover p:hover {
  border-bottom: 1px solid var(--label-primary);
  cursor: pointer;
}
</style>
