<!--

== Base Input

Props:

    > design
    Tipo de estilo do botão, deve ser uma string variando entre números em inglês. Existentes hoje: One.

    > text
    Texto que vai dentro do botão.


Slot:
- Utilize para inserir elementos dentro do botão, como um icone, porem ao usar o slot, o props text não irá mais funcionar.

-->

<template>
  <button
    :class="`btn btn-${design} ${loading ? 'in-load' : ''}`"
    v-bind="$attrs"
    :value="value"
    v-on="inputListeners"
    :disabled="disabled"
  >
    <span v-if="!$slots.default" :style="loading ? 'opacity: 0' : ''">{{
      text
    }}</span>

    <i class="fal fa-save" v-if="design === 'save'"></i>

    <span
      v-if="loading"
      :class="`loading ${design === 'six' ? 'loading-six' : ''}`"
    >
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </span>
    <!-- Conteúdo para ser clicado (Não obrigatório) -->
    <slot :style="loading ? 'opacity: 0' : ''"></slot>
  </button>
</template>

<script>
import { baseEvents } from '@/mixins/baseEvents.js';

export default {
  mixins: [baseEvents],
  props: {
    text: { type: String },
    design: { type: String, default: 'one' },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.btn {
  position: relative;
  text-align: center;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  padding: 10px 15px;
  font-size: 12px;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 45px;
  min-height: 45px;
}

.btn.big {
  min-width: 50px;
  padding: 15px 40px;
}

.btn.icon {
  padding: 0;
}

.action-buttons .btn {
  font-weight: 700;
  min-width: 47px;
  min-height: 47px;
  line-height: 1;
}

.action-buttons i {
  font-size: 15px;
}

.grayscale {
  filter: grayscale(1);
  pointer-events: none;
}

.drawer-footer button {
  height: 45px;
}

.drawer-footer .btn i {
  font-size: 14px;
}

.btn-one {
  background-color: var(--default);
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 12px;
}

.btn-one:hover:not(:disabled) {
  background-color: var(--default-hover);
}

.btn-two {
  border: 1px solid var(--default);
  color: var(--default);
  background-color: transparent;
}

.btn-two:hover:not(:disabled) {
  background-color: #292f33;
}

button.mini {
  padding: 10px;
  min-height: auto;
  height: 33px;
  min-width: 33px;
}
button.btn-five.mini {
  border-color: #343b40;
}
button.mini:not(.icon) {
  padding: 10px 40px;
}

.btn-three {
  padding: 12px 15px;
  font-size: 12px;
  line-height: 15px;
  background-color: transparent;
  border: 1px solid #343b40;
  color: #aaaaab;
  font-weight: 500;
}

.btn-three i {
  color: #6f767e;
}

.btn-three:hover:not(:disabled) {
  background-color: #292f33;
}

.btn-four {
  padding: 10px;
  font-size: 12px;
  background-color: var(--box);
  border: 1px solid #343b40;
  color: #aaaaab;
}

.btn-four:hover:not(:disabled),
.btn-four-mini:hover:not(:disabled) {
  background-color: #292f33;
}

.btn-four-mini {
  border: 1px solid #99999980;
  width: 32px;
  height: 32px;
  min-width: auto;
  padding: 0;
  color: var(--label-primary);
  background-color: var(--box);
}

.btn-five {
  background-color: transparent;
  border: 1px solid #3e464d;
  color: var(--label-primary);
  font-weight: 700;
}

.btn-five i {
  color: var(--icon-inactive);
}

.btn-five:hover:not(:disabled) {
  background-color: #292e32;
}

.btn-five:disabled {
  background: var(--box);
  opacity: 0.5;
}

.btn-six {
  background-color: transparent;
  border: 1px solid #999999;
  color: var(--label-primary);
}

.btn-six i {
  color: var(--icon-inactive);
}

.btn-six:hover:not(:disabled) {
  background: #44464a;
}

.btn-underline {
  outline: none;
  background: transparent;
  border: none;
  opacity: 0.75;
  transition: all 0.1s ease;
}

.btn-underline:hover {
  opacity: 1;
}

.btn-underline span {
  color: var(--label-secondary);
  border-radius: 0;
  padding-bottom: 3px;
  border-bottom: 1px solid transparent;
}

.btn-underline:hover:not(:disabled) span {
  border-color: var(--label-secondary);
}

.btn-save {
  border-radius: 50%;
  background: var(--default);
  height: 55px;
  width: 55px;
  color: #fff;
  position: fixed;
  bottom: 45px;
}

@media screen and (max-width: 991px) {
  .btn-save {
    bottom: 75px;
  }
  .no-template .btn-save {
    bottom: 25px;
  }
}

.btn-save:hover:not(:disabled) {
  background-color: var(--default-hover);
}

.btn-save i {
  font-size: 18px;
}

.btn-three.link {
  border: none;
}

.title-btn {
  margin-left: 15px;
}

.input-size {
  margin-top: 10px;
  height: 41px;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.2;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--default);
  border-radius: 5px;
}
.loading-six {
  background: var(--button-outline);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 8px;
  border: 1px solid white;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
