<template>
  <div>
    <base-card v-if="!showRemindTags">
      <base-label text="Deseja ativar  lembrete de  expiração?" />
      <base-radio
        family="expirationReminder"
        v-model="showCashbackRemind"
        @click="toggleCashbackRemind('yes')"
        option="yes"
      >
        <p class="radio-label">Sim</p>
      </base-radio>
      <base-radio
        family="expirationReminder"
        v-model="showCashbackRemind"
        @click="toggleCashbackRemind('no')"
        option="no"
        class="mt_15"
      >
        <p class="radio-label">Não</p>
      </base-radio>
    </base-card>

    <div v-if="showCashbackRemind === 'yes'">
      <base-card v-if="!showRemindTags">
        <shipping-methods-cashback
          :shippingMethod="cashbackValues.expiration_message.type"
          @selectShippingMethod="
            selectShippingMethod($event, 'expiration_message')
          "
        />
      </base-card>

      <base-card v-if="!showRemindTags">
        <base-label text="Defina o período e a hora do disparo" />
        <div class="d-flex">
          <base-input
            design="three"
            v-model="cashbackValues.expiration_message.remind_days_before"
            :thousandSeparator="false"
            :moneyMask="StringLanguage.timeConfigs.days"
            class="w-100"
          />

          <base-input
            design="three"
            type="time"
            v-model="cashbackValues.expiration_message.remind_hour"
            class="w-100 ml_15"
          />
        </div>

        <base-checkbox class="mt_5">
          <p class="text-checkbox">Aprendizagem automática</p>
        </base-checkbox>
      </base-card>

      <div v-if="showRemindTags">
        <base-card>
          <div class="use-of-tags">
            <p class="use-of-tags-1">
              Utilize as tags a seguir para construir sua mensagem, e veja uma
              pré-visualização completa da mensagem logo abaixo.
            </p>

            <div class="d-flex flex-column align-center">
              <!-- <span class="tag">&lt; benefício ></span> -->
              <span class="tag">&lt; link ></span>
              <span class="tag">&lt; valorrecebido ></span>
            </div>

            <p class="use-of-tags-2">
              O uso das tags é obrigatório e não será possível avançar sem
              inseri-las na mensagem.
            </p>
          </div>
        </base-card>

        <base-card>
          <base-label text="Digite a mensagem que será enviada" />
          <base-textarea
            label="Mensagem"
            v-model="messages.remind.withGaps"
            :minHeight="100"
            @input="replaceGaps($event, 'remind')"
          />
          <div class="d-flex justify-end">
            <p class="count-characters">
              <span class="number">{{ getTotalSMS('remind') }}</span>
              <span class="type">
                &nbsp;{{ getTotalSMS('remind') > 1 ? 'mensagens' : 'mensagem' }}
              </span>

              <span class="number">{{ getTotalChars('remind') }}</span>
              <span class="type">&nbsp;caracteres</span>
            </p>
          </div>

          <base-message class="mt_20 mb_50" v-if="getTotalSMS('remind') > 1">
            A cada 160 caracteres utilizados é realizado 1 disparo. Na mensagem
            definida nesse momento, 2 ou mais disparos serão realizados. Mas não
            se preocupe, seu cliente verá tudo como uma mensagem única.
          </base-message>

          <base-label text="Pré-visualização" />
          <pre class="preview remind" v-html="messages.remind.preview"></pre>
        </base-card>

        <test-shooting-card
          :message="messages.remind.withGaps"
          :url="{ type: 'STANDARD', value: defaultWalletUrl }"
          :beneficio="{ type: 'CASHBACK_PROGRAM', value: cashback.percentage }"
          :valorrecebido="1.41"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseCard from '@/components/Card';
import BaseCheckbox from '@/components/Checkbox';
import BaseRadio from '@/components/Radio';
import BaseTextarea from '@/components/Textarea';
import BaseMessage from '@/components/Message';
import ShippingMethodsCashback from '@/pages/Cashback/ShippingMethods.vue';
import TestShootingCard from '@/containers/TestShootingCard.vue';

import { TestShootingAPI, TestShootingSmsBody } from '@/api/TestShootingAPI';
import { EnvironmentsAPI } from '@/api/Environments';

const maxCharsPerMessage = 160;

export default {
  components: {
    BaseLabel,
    BaseInput,
    BaseCard,
    BaseRadio,
    BaseCheckbox,
    BaseTextarea,
    BaseMessage,
    ShippingMethodsCashback,
    TestShootingCard,
  },
  props: {
    showRemindTags: { type: Boolean, default: false },
    cashback: {
      type: Object,
      default: () => ({
        expiration_message: {
          type: '',
          text: '',
          remind_days_before: '',
          remind_hour: '',
        },
      }),
    },
  },
  async beforeMount() {
    const envInfo = await EnvironmentsAPI.me();
    this.environment = envInfo.getData({});
    
    const envConfigurations = await EnvironmentsAPI.getConfigurations();
    this.configurations = envConfigurations.getData({});
    this.defaultShortenedUrl = (this.configurations['short-urls'] && this.configurations['short-urls'].rawShortWallet) ? 
        this.configurations['short-urls'].rawShortWallet : 'https://4d5c.short.gy/nDVNA7';
  },
  mounted() {
    setTimeout(() => {
      if (this.cashback.expiration_message) {
        this.replaceGaps(this.cashback.expiration_message.text, 'remind');
      }
    }, 500);
  },
  data() {
    const hasRemindMessage =
      this.cashback.expiration_message && this.cashback.expiration_message.type;
    return {
      environment: null,
      defaultShortenedUrl: '',
      isWaitingSending: false,
      remainTime: 0,
      showCashbackRemind: hasRemindMessage ? 'yes' : 'no',
      messages: {
        remind: {
          withGaps: hasRemindMessage
            ? this.cashback.expiration_message.text
            : '',
          preview: '',
          final: '',
        },
      },
      testPhone: {
        ddd: '',
        number: '',
      },
      cashbackValues: {
        percentage: this.cashback.percentage,
        expiration_message: hasRemindMessage
          ? JSON.parse(JSON.stringify(this.cashback.expiration_message))
          : null,
      },
    };
  },
  methods: {
    toggleCashbackRemind(option) {
      if (option == 'yes') {
        this.cashbackValues.expiration_message = {
          type: '',
          text: '',
          remind_days_before: 1,
          remind_hour: '',
        };
      }

      this.showCashbackRemind = option;
      this.emitChange();
    },
    emitChange() {
      this.$emit(
        'changed',
        {
          expiration_message:
            this.showCashbackRemind === 'yes'
              ? {
                  type: this.cashbackValues.expiration_message.type,
                  text: this.messages.remind.final,
                  remind_days_before:
                    this.cashbackValues.expiration_message.remind_days_before,
                  remind_hour:
                    this.cashbackValues.expiration_message.remind_hour,
                }
              : null,
        },
        null,
        this.showCashbackRemind
      );
    },
    formatPercent() {
      return `${+this.cashback.percentage}%`;
    },
    selectShippingMethod(value, type) {
      this.cashbackValues[type].type = value;
      this.emitChange();
    },
    formatCurrency(value) {
      const formated = parseFloat(value);

      return formated.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    replaceGaps(event, type) {
      const { percentage } = this.cashback;
      const hasValue = percentage ? `${percentage}` : '';

      const gapColors = event
        .replaceAll(
          '<link>',
          `<span style="color:#6161ff">${this.defaultShortenedUrl}</span>`
        )
        .replaceAll(
          '<valorrecebido>',
          `<span style="color:#6161ff">${this.formatCurrency(1.41)}</span>`
        )
        .replace(/(<\/?(?:span)[^>]*>)|<[^>]+>/gi, '$1');

      const previewWithGapColors = document.querySelector(`.preview.${type}`);
      previewWithGapColors.innerHTML = `${gapColors}`;

      this.messages[type].preview = `${gapColors}`;
      this.messages[type].final = `${event}`;

      this.emitChange();
    },
    getTotalChars(type) {
      return this.messages[type].final.length;
    },
    getTotalSMS(type) {
      return Math.ceil(this.messages[type].final.length / maxCharsPerMessage);
    },
    async sendSmsTest() {
      this.isWaitingSending = true;
      const response = await TestShootingAPI.postSms(
        new TestShootingSmsBody({
          phone: `${this.testPhone.ddd}${this.testPhone.phone}`,
          message: this.messages['receive'].final,
          replacements: {
            url: { type: 'STANDARD' },
            beneficio: {
              type: 'CASHBACK_PROGRAM',
              value: this.cashback.percentage,
            },
            valorrecebido: 1.41,
          },
        })
      );

      if (response.getCode(500) != 200) {
        alert('Ocorreu um erro ao disparar a mensagem de teste');
        this.isWaitingSending = false;
        return;
      }

      this.remainTime = 180;
      const intervalId = setInterval(() => {
        this.remainTime--;
        if (this.remainTime == 0) {
          clearInterval(intervalId);
          this.isWaitingSending = false;
        }
      }, 1000);
    },
  },
  watch: {
    cashbackValues: {
      deep: true,
      handler() {
        this.emitChange();
      },
    },
  },
};
</script>
<style scoped>
.use-of-tags {
  background: rgba(46, 52, 56, 0.5);
  border-radius: 10px;
  padding: 20px;
}

.use-of-tags-1 {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #aaaaab;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.use-of-tags-2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #aaaaab;
  background: rgba(46, 52, 56, 0.75);
  border-radius: 10px;
  padding: 20px 50px;
  margin-top: 15px;
}

.tag {
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #6161ff;
}

.count-characters {
  background: rgba(53, 61, 66, 0.33);
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.count-characters .number {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(177, 177, 178, 0.9);
}

.count-characters .type {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.85);
}

.count-characters .type:nth-child(2) {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid rgba(111, 118, 126, 0.35);
}

.preview {
  background: #222629;
  border-radius: 15px;
  padding: 15px;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #aaaaaa;
  min-height: 90px;
  word-break: break-all;
  white-space: break-spaces;
}

.enter-phone {
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.75);
  margin-bottom: 20px;
}

.fire-test-message {
  background: rgba(46, 52, 56, 0.3);
  border-radius: 10px;
  padding: 20px;
}
</style>
