<!--

== Base Title

- Props:

    > title
    Título principal

    > subtitle
    Subtitulo, geralmente como um subnível dentro do título.

    > link
    Name de uma rota, para redirecionar quando clicado.


- Slot:
Deve ser utilizado para inserir elementos no lado oposto ao do título (Lado direito), o principal uso é para action buttons.

-->

<template>
  <div>
    <div class="title-box">
      <div
        class="titles"
        :class="{ 'is-link': link, mb_20: $slots.tag && isMobile }"
      >
        <div class="title-section">
          <div @click="backTo()">
            <h1>{{ title }}</h1>
            <h2 v-if="subtitle">
              <span v-if="hasBackTo || link" class="back"
                ><i class="fal fa-angle-left"></i
              ></span>
              <span v-else>/ /</span>
              <p class="subtitle">{{ subtitle }}</p>
            </h2>
          </div>

          <base-breadcrumb :items="breadcrumbs" v-if="breadcrumbs.length" />
        </div>
        <div v-if="isMobile" class="d-flex">
          <div v-if="$slots.info">
            <slot name="info"></slot>
          </div>

          <div v-if="showSearch" class="toggle-ab search" @click="openDrawer()">
            <i class="far fa-search" />
          </div>

          <div class="d-flex">
            <div v-if="(date || []).length > 0" class="dates">
              <p>{{ date[0] }}<span class="dash">-</span>{{ date[1] }}</p>
              <span class="close" @click="hideDates()"
                ><i class="far fa-times"
              /></span>
            </div>
            <p v-if="$slots.default" @click="abMobile = true" class="toggle-ab">
              <i class="far fa-ellipsis-vertical"></i>
            </p>
          </div>

          <div v-if="addAction && showButtonAdd">
            <p @click="$emit('addAction')" class="toggle-ab">
              <i class="fal fa-plus"></i>
            </p>
          </div>
        </div>
      </div>

      <div class="buttons">
        <div v-if="$slots.tag && !isMobile" class="tag">
          <slot name="tag"></slot>
        </div>
        <div v-if="$slots.info">
          <slot name="info"></slot>
        </div>
        <div v-if="(date || []).length > 0 && !isMobile" class="dates">
          <p>{{ date[0] }}<span class="dash">-</span>{{ date[1] }}</p>
          <span class="close" @click="hideDates()"
            ><i class="far fa-times"
          /></span>
        </div>
        <div
          v-if="!isMobile"
          class="action-buttons"
          :class="{ opened: abMobile }"
        >
          <div ref="infoBox" @click="abMobile = false">
            <slot></slot>
          </div>
        </div>
      </div>

      <div v-if="$slots.tag && isMobile" class="tag">
        <slot name="tag"></slot>
      </div>
    </div>

    <!-- Title buttons mobile -->
    <div v-if="isMobile && $slots.default">
      <div
        class="tb-fade"
        :class="{ active: abMobile }"
        @click="abMobile = false"
      ></div>
      <div class="title-buttons" :class="{ opened: abMobile }">
        <div class="header">
          <p v-if="isMobile" class="title">Opções</p>
          <i class="far fa-times" @click="abMobile = false" />
        </div>

        <slot></slot>
      </div>
    </div>

    <base-drawer
      :open="drawer.open"
      :close="drawer.close"
      :opacity="8"
      direction="top"
      search
    >
      <form @submit.prevent>
        <base-input
          placeholder="Buscar por..."
          search
          class="search-mobile"
          ref="search"
          @search="search($event)"
        />
      </form>
    </base-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import BaseBreadcrumb from '@/components/Breadcrumb';
import BaseDrawer from '@/components/Drawer';
import BaseInput from '@/components/Input';

export default {
  data() {
    return {
      abMobile: false,
      drawer: {
        open: false,
        close: false,
      },
    };
  },
  props: {
    title: { required: true },
    subtitle: String,
    hasBackTo: { type: Boolean, default: false },
    link: { type: [Object, String] },
    addAction: { type: Function, default: () => {} },
    showButtonAdd: { type: Boolean, default: false },
    breadcrumbs: { type: Array, default: () => [] },
    showSearch: { type: Boolean, default: false },
    date: { type: Array, default: () => [] },
  },
  methods: {
    hideDates() {
      this.$emit('hideDate');
    },
    search(value) {
      this.$emit('search', value);

      this.closeDrawer();
    },
    backTo() {
      if (this.link) {
        if (typeof this.link !== 'string' && typeof this.link === 'object') {
          this.$router.push({ name: this.link.name, params: this.link.params });
          return;
        }
        this.$router.push({ name: this.link });
      } else {
        this.$emit('backTo');
      }
    },
    openDrawer() {
      this.drawer.open = !this.drawer.open;

      this.$refs.search.focus();
    },
    closeDrawer() {
      this.$refs.search.blur();

      this.drawer.close = !this.drawer.close;
      this.drawer.open = !this.drawer.open;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
  components: {
    BaseBreadcrumb,
    BaseDrawer,
    BaseInput,
  },
};
</script>
<style scoped>
.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
  min-height: 62px;
  flex-wrap: wrap;
}

.title-box .titles {
  font-family: 'Rubik', sans-serif;
  z-index: 550;
}

.title-section {
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.title-box .back {
  margin-left: 2px;
  margin-right: 8px;
  font-size: 16px;
  color: var(--default);
}

.title-box .titles h1 {
  font-size: 2.2em;
  font-weight: 400;
  color: #d9d9d9;
}

.title-box .titles h2 {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 300;
  color: #d9d9d9;
  display: flex;
  align-items: center;
  opacity: 0.8;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
}

.title-box .titles h2 .subtitle {
  margin-left: 10px;
}

.is-link {
  cursor: pointer;
}

.action-buttons > div {
  display: flex;
}

.action-buttons input.search {
  background: #252a2e8c;
  border: none;
}

.tag {
  background: #292a2d;
  padding: 10px 15px;
  font-size: 12px;
  color: #aaaaab;
  border-radius: 5px;
  border: 1px solid #515159;
  position: relative;
  margin-right: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 16px;
}

.tag:hover {
  background: var(--box) 33;
}

.remove-filter {
  font-size: 12px;
  cursor: pointer;
  margin-left: 10px;
  color: #8c8c8c;
}

@media screen and (max-width: 991px) {
  .tag {
    margin-bottom: 5px;
    margin-right: 0;
  }
  .title-box {
    min-height: 52px;
    margin-bottom: 20px;
    font-size: 9px;
  }
  .title-box .titles h1 {
    font-size: 18px;
    font-weight: 400;
    color: #c9c9c9;
  }

  .title-box .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .title-box .back {
    margin-top: 0;
  }

  .title-box .titles .toggle-ab {
    background-color: var(--default);
    color: white;
    font-size: 14px;
    border-radius: 10px;
    margin-left: 15px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-box .titles .toggle-ab.search {
    background: rgba(37, 42, 46, 0.55);
    color: var(--icon-inactive);
  }

  .title-buttons {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: -500px;
    z-index: 998;
    transition: 0.2s linear;
    background: var(--box);
    padding: 30px 25px 40px;
    border-radius: 20px 20px 0 0;
  }

  .title-buttons.opened {
    bottom: 0 !important;
  }

  .title-buttons .header {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(111, 118, 126, 0.15);
    display: flex;
    align-items: center;
  }

  .title-buttons .header .title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-weight: 800;
    color: rgba(235, 235, 235, 0.55);
  }

  .title-buttons .header i {
    font-size: 16px;
    color: var(--icon-inactive);
  }

  .tb-fade {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 997;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s linear;
  }

  .tb-fade.active {
    opacity: 1;
    pointer-events: unset;
  }
}

.buttons {
  display: flex;
  align-items: center;
  z-index: 550;
}

.dates {
  padding: 10px 15px;
  background: rgba(97, 97, 255, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #4c4cff;
  margin-right: 25px;
}

@media screen and (max-width: 991px) {
  .dates {
    margin-right: 0;
  }
}

.dates .dash {
  display: block;
  padding: 0 5px;
}

.dates p {
  display: flex;
  align-items: center;
  width: 100%;
}

.dates .close {
  margin-left: 15px;
  cursor: pointer;
}
</style>
