<template>
  <div class="tabs">
    <div
      v-for="(sTab, iTab) in filteredTabs"
      :key="iTab"
      class="tab"
      :class="{ active: sTab.name === tabActive }"
      @click="changeTab(sTab)"
    >
      <p class="tab-name">{{ sTab.name }}</p>
    </div>
    <div
      class="border"
      :style="`width: ${100 / tabs.length}%; transform: translateX(${
        tabActived * 100
      }%) `"
    ></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      realTabs: this.tabs,
      tabActive: this.tabs[0].name,
    };
  },
  props: {
    tabs: { type: Array, required: true },
    tabActived: {},
  },
  methods: {
    changeTab(sTab) {
      this.tabActive = sTab.name;
      this.$emit('change', sTab.value);
    },
  },
  created() {
    if (typeof this.tabActived === 'number') {
      this.tabActive = this.tabs[this.tabActived].name;
    }
  },
  watch: {
    tabActived(newVal) {
      if (typeof newVal === 'number') {
        const selectedTab = this.tabs.find((tab, index) => index === newVal);
        this.tabActive = selectedTab.name;
      }
    },
    tabs(newVal) {
      this.realTabs = newVal;
    },
  },
  computed: {
    filteredTabs() {
      return this.realTabs.filter(
        (item) => item.enabled || item.enabled === undefined
      );
    },
  },
};
</script>
<style scoped>
.border {
  position: absolute;
  bottom: -2px;
  height: 3px;
  background: var(--default);
  transition: transform 0.25s ease;
}
.tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #6f767e26;
  width: calc(100% - 50px);
  margin: 0 auto 30px;
  position: relative;
}
.tabs .tab {
  color: #aaaaabb3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  font-size: 14px;
  line-height: 17px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  transform: translateY(2px);
  width: 100%;
}
.tabs .tab.active {
  color: var(--default);
  font-weight: 700;
}
.tabs .tab:not(:last-child) {
  border-right: none;
}
</style>
