import {
  Chart,
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from 'chart.js';

Chart.register(
  LineController,
  LineElement,
  BarController,
  BarElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale
);

export default {
  render: function (createElement) {
    return createElement(
      'div', { style: this.styles, class: this.cssClasses },
      [createElement('canvas', { attrs: { id: this.chartId, width: this.width, height: this.height }, ref: 'canvas' })]
    )
  },
  props: {
    chartId: { default: 'easy-chart-line', type: String },
    chartType: { default: 'line', required: true, type: String },
    chartData: { type: Object },
    chartOptions: { type: Object },
    width: { default: 400, type: Number },
    height: { default: 400, type: Number },
    cssClasses: { type: String, default: 'h-100' },
    styles: { type: Object },
    plugins: { type: Array, default() { return [] } },
    reload: {
      default: null
    }
  },

  data() {
    return {
      _chart: null,
      _plugins: this.plugins
    }
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    addPlugin(plugin) {
      this.$data._plugins.push(plugin)
    },
    generateLegend() {
      if (this.$data._chart) {
        return this.$data._chart.generateLegend()
      }
    },
    renderChart() {
      if (this.$data._chart) this.$data._chart.destroy()
      this.$data._chart = new Chart(this.$refs.canvas.getContext('2d'), { type: this.chartType || 'line', data: this.chartData, options: this.chartOptions, plugins: this.$data._plugins })
    }
  },
  beforeDestroy() {
    if (this.$data._chart) {
      this.$data._chart.destroy()
    }
  },
  watch: {
    reload() {
      this.renderChart()
    }
  }

}