<template>
  <div class="box" :class="sortBox ? 'sort-box' : ''" v-bind="$attrs">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    sortBox: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.box {
  background-color: var(--box);
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  margin-bottom: 30px;
}

.sort-box {
  background-color: var(--box-up);
  margin-bottom: 5px;
  height: 45px;
}
</style>
