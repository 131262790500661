<template>
  <div ref="pullRefresh"></div>
</template>

<script>
import { mapState } from 'vuex';
import router from '@/router';
import AppBridge from "@/plugins/app";

export default {
  mounted() {
    this.scrollTrigger();
  },
  methods: {
    scrollTrigger() {
      if (AppBridge.isApp()) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (
              entry.intersectionRatio > 0 &&
              router.currentRoute.name !== 'horario' // desativado na tela de horarios por incompatibilidade com select
            ) {
              AppBridge.emit('setActivePullRefresh', true);
            } else {
              AppBridge.emit('setActivePullRefresh', false);
            }
          });
        });

        observer.observe(this.$refs.pullRefresh);
      }
    },
  },
  watch: {
    hasDrawerOpened(status) {
      if (status) AppBridge.emit('setActivePullRefresh', false);
      else AppBridge.emit('setActivePullRefresh', true);
    },
  },
  computed: {
    ...mapState({
      hasDrawerOpened: (state) => state.store.hasDrawerOpened,
    }),
  },
};
</script>
