<template>
  <div class="lds-ellipsis" :style="`height: ${size}px; width: ${size * 6}px`">
    <div :style="getStyle()"></div>
    <div :style="getStyle()"></div>
    <div :style="getStyle()"></div>
    <div :style="getStyle()"></div>
  </div>
</template>
<script>
export default {
  props: {
    size: { type: String, default: '13' },
    color: { type: String, default: 'var(--default)' },
  },
  methods: {
    getStyle() {
      return `
                height: ${this.size}px;
                width: ${this.size}px;
                background: ${this.color};
            `;
    },
  },
};
</script>
<style scoped>
/* LOADER */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  z-index: 999;
}
.lds-ellipsis div {
  position: absolute;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
