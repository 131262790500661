<template>
  <div>
    <div
      class="select"
      ref="selectDropdown"
      :class="`${!!design ? `design-${design}` : ''} ${
        !valid ? 'invalid' : ''
      }`"
      :tabindex="0"
      @blur="handleBlur"
    >
      <div class="select-current">
        {{ currentValue.text }}
      </div>
      <div class="options-container" v-if="!close">
        <ul class="options-list">
          <li
            class="option"
            v-for="(option, iOption) in getOptions()"
            :key="`${id + iOption}`"
          >
            <label :for="`${id + iOption}`">{{ option.text }}</label>
            <input
              type="radio"
              v-model="insideValue"
              :id="`${id + iOption}`"
              :value="option.value"
              :name="id"
              @click="
                (close = true),
                  (currentValue.text = option.text),
                  handleBlur('blur');
                onChange(option.value);
              "
            />
          </li>
        </ul>
      </div>

      <label class="label" v-if="title">{{ title }}</label>
      <span class="chevron"><i class="far fa-chevron-down" /></span>
    </div>

    <div class="select-mobile" :class="{ invalid: !valid }" v-show="isMobile">
      <select
        ref="select"
        v-bind="$attrs"
        v-model="insideValue"
        :id="id"
        :value="value"
        @change="onChange($event.target.value)"
      >
        <option
          v-for="(option, iOption) in getOptions()"
          :selected="option.selected"
          :key="iOption"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>

      <label class="label">{{ title }}</label>
      <span class="chevron"><i class="fal fa-chevron-down" /></span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { baseEvents } from '@/mixins/baseEvents.js';

export default {
  mixins: [baseEvents],
  data() {
    return {
      id: '',
      insideValue: this.value,
      selected: '',
      close: false,
    };
  },
  props: {
    title: { type: String },
    valid: { type: Boolean, default: true },
    design: { type: String, default: 'one' },
    items: { type: Array, require: false },
  },
  created() {
    this.id = Math.random().toString(36).substring(2, 15);
  },
  mounted() {
    Vue.nextTick(() => {
      this.checkOptions();
    });
  },
  updated() {
    Vue.nextTick(() => {
      this.checkOptions();
    });
  },
  methods: {
    onChange(value) {
      this.insideValue = value;
      this.$emit('change', value);
    },
    handleBlur(blur) {
      this.close = false;

      if (blur) {
        this.$refs.selectDropdown.blur();
      }
    },
    checkOptions() {
      this.$emit('input', this.$refs.select.value);
      return true;
    },
    getOptions() {
      const selectedValue = this.$refs.select
        ? this.$refs.select.value
        : this.insideValue;

      if (this.$slots.default) {
        return this.$slots.default.map((option) => {
          const value =
            option.value ||
            (option.data.domProps ? option.data.domProps.value : false);
          return {
            text:
              option.children[0] && option.children[0].text
                ? option.children[0].text
                : 'dsadsa',
            value,
            selected: value === selectedValue,
          };
        });
      }

      return this.items.filter((option) => {
        if (!option.disabled) {
          return {
            text: option.text,
            value: option.value,
            selected: selectedValue === option.value,
          };
        }

        return true;
      });
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
    currentValue() {
      if (this.$slots.default) {
        const result = this.getOptions().find((option) => {
          return option.value == this.insideValue;
        });

        if (!result)
          return {
            text: this.insideValue,
            value: '',
          };

        return result;
      }

      const result = this.items.find((option) => {
        return option.value == this.insideValue;
      });

      if (!result)
        return {
          text: this.insideValue,
          value: '',
        };

      return result;
    },
  },
  watch: {
    insideValue: {
      deep: true,
      handler() {
        this.$emit('change', { target: this.$refs.select });
      },
    },
    value(newValue) {
      this.insideValue = newValue;
    },
  },
};
</script>

<style scoped>
.select,
.select-mobile {
  position: relative;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 100%;
}
.select-current,
.select-mobile select {
  border: 1px solid var(--input-border);
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 10px;
  overflow: hidden;
  font-size: 12px;
  background: transparent;
  width: 100%;
  color: #a3a3a3;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select:focus {
  outline: none;
}
.select:focus .select-current {
  border-radius: 10px 10px 0 0;
  border-color: var(--default);
}
.select-mobile select:focus-visible {
  border-color: var(--default);
  outline: none;
}
.select:focus .options-container {
  opacity: 1;
  z-index: 2;
}
.select:focus .options-list .option label {
  cursor: pointer;
}
.select .options-list .option input[type='radio'] {
  display: none;
}
.options-container {
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  top: 55px;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
}
.select:focus .options-container {
  overflow: hidden;
  border-radius: 0 0 5px 5px;
}
.options-list {
  max-height: 300px;
  overflow-y: auto;
}
.options-list .option {
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 12px;
  background-color: var(--box-up);
  font-size: 12px;
  color: #a3a3a3;
}
.option > label {
  height: 45px;
  padding: 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
}
.option:hover,
.option:focus {
  color: #fff;
  background-color: var(--default);
}
.chevron {
  position: absolute;
  bottom: 15px;
  right: 15px;
  font-size: 12px;
  color: var(--input-text);
  transition: all 0.1s;
}
.select:focus .chevron,
.select-mobile select:focus-visible + .chevron {
  transform: rotate(180deg);
}
.select.invalid .select-current,
.select-mobile.invalid select {
  border: 2px solid #9f3041;
}
.select .label,
.select-mobile .label {
  position: absolute;
  padding: 5px 10px;
  top: 0px;
  left: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  background-color: var(--box);
  color: var(--input-text);
  pointer-events: none;
}

.select.design-three .label,
.select-mobile.design-three .label {
  background-color: #2a3034;
}

@media screen and (max-width: 991px) {
  .select {
    display: none;
  }
}
</style>
