<template>
  <div :class="`mode-info ${design}`">
    <div v-for="(message, index) in messages" :key="index" class="message">
      <i class="fas fa-info-circle"></i>
      <span>{{ message }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    design: { type: String, default: 'gold' },
    messages: { type: Array, default: [] },
  },
};
</script>
<style scoped>
.mode-info:not(:empty) {
  padding: 10px 15px;
  font-size: 12px;
  min-height: 35px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mode-info i {
  margin-right: 10px;
}

.mode-info.gold {
  color: #d1802e;
  padding: 15px;
  height: auto;
  background-color: #655950;
}

.mode-info.red {
  color: #f5a2af;
  background-color: #7f3541;
}

.message {
  display: flex;
  align-items: center;
}

.message span {
  line-height: 1.2;
}

.mode-info .message:not(:last-child) {
  margin-bottom: 10px;
}
</style>
