<template>
  <div :class="`mode-info ${design}`">
    <i class="fas fa-info-circle"></i>
    <span>
      <slot></slot>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    design: { type: String, default: 'gold' },
  },
};
</script>
<style scoped>
.mode-info {
  padding: 10px 15px;
  font-size: 12px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  line-height: 20px;
}

.mode-info i {
  margin-right: 10px;
}

.mode-info.gold {
  color: rgba(255, 241, 173, 1);
  padding: 15px;
  height: auto;
  background-color: rgba(255, 241, 173, 0.25);
}

.mode-info.red {
  color: #f5a2af;
  background-color: #7f3541;
}
</style>
