<template>
  <div>
    <base-card v-if="!showReceiveTags">
      <base-label text="Você deseja comunicar o recebimento do cashback?" />
      <base-radio
        family="cashbackReceive"
        v-model="showCashbackReceive"
        @click="toggleCashbackReceive('yes')"
        option="yes"
      >
        <p class="radio-label">Sim</p>
      </base-radio>
      <base-radio
        family="cashbackReceive"
        v-model="showCashbackReceive"
        @click="toggleCashbackReceive('no')"
        option="no"
        class="mt_15"
      >
        <p class="radio-label">Não</p>
      </base-radio>
    </base-card>

    <div v-if="showCashbackReceive === 'yes'">
      <base-card v-if="!showReceiveTags">
        <shipping-methods-cashback
          :shippingMethod="
            cashbackValues.message && cashbackValues.message.type
          "
          @selectShippingMethod="selectShippingMethod($event, 'message')"
        />
      </base-card>

      <div v-if="showReceiveTags">
        <base-card>
          <div class="use-of-tags">
            <p class="use-of-tags-1">
              Utilize as tags a seguir para construir sua mensagem, e veja uma
              pré-visualização completa da mensagem logo abaixo.
            </p>

            <div class="d-flex flex-column align-center">
              <span class="tag">&lt; beneficio ></span>
              <span class="tag">&lt; link ></span>
              <span class="tag">&lt; valorrecebido ></span>
            </div>

            <p class="use-of-tags-2">
              O uso das tags é obrigatório e não será possível avançar sem
              inseri-las na mensagem.
            </p>
          </div>
        </base-card>

        <base-card>
          <base-label text="Digite a mensagem que será enviada" />
          <base-textarea
            label="Mensagem"
            v-model="messages.receive.withGaps"
            :minHeight="100"
            @input="replaceGaps($event, 'receive')"
          />
          <div class="d-flex justify-end">
            <p class="count-characters">
              <span class="number">{{ getTotalSMS('receive') }}</span>
              <span class="type">
                &nbsp;{{
                  getTotalSMS('receive') > 1 ? 'mensagens' : 'mensagem'
                }}
              </span>

              <span class="number">{{ getTotalChars('receive') }}</span>
              <span class="type">&nbsp;caracteres</span>
            </p>
          </div>

          <base-message class="mt_20 mb_50" v-if="getTotalSMS('receive') > 1">
            A cada 160 caracteres utilizados é realizado 1 disparo. Na mensagem
            definida nesse momento, 2 ou mais disparos serão realizados. Mas não
            se preocupe, seu cliente verá tudo como uma mensagem única.
          </base-message>

          <base-label text="Pré-visualização" />
          <pre class="preview receive" v-html="messages.receive.preview"></pre>
        </base-card>

        <test-shooting-card
          :message="messages.receive.withGaps"
          :url="{ type: 'STANDARD', value: defaultWalletUrl }"
          :beneficio="{ type: 'CASHBACK_PROGRAM', value: cashback.percentage }"
          :valorrecebido="1.41"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseLabel from '@/components/Label';
import BaseCard from '@/components/Card';
import BaseRadio from '@/components/Radio';
import BaseTextarea from '@/components/Textarea';
import BaseMessage from '@/components/Message';
import ShippingMethodsCashback from '@/pages/Cashback/ShippingMethods.vue';
import TestShootingCard from '@/containers/TestShootingCard.vue';

const maxCharsPerMessage = 160;

import { TestShootingAPI, TestShootingSmsBody } from '@/api/TestShootingAPI';
import { EnvironmentsAPI } from '@/api/Environments';

export default {
  components: {
    BaseLabel,
    BaseCard,
    BaseRadio,
    BaseTextarea,
    BaseMessage,
    TestShootingCard,
    ShippingMethodsCashback,
  },
  props: {
    showReceiveTags: { type: Boolean, default: false },
    cashback: {
      type: Object,
      default: () => ({
        message: {
          type: '',
          text: '',
        },
      }),
    },
  },
  async beforeMount() {
    const envInfo = await EnvironmentsAPI.me();
    this.environment = envInfo.getData({});
    
    const envConfigurations = await EnvironmentsAPI.getConfigurations();
    this.configurations = envConfigurations.getData({});
    this.defaultShortenedUrl = (this.configurations['short-urls'] && this.configurations['short-urls'].rawShortWallet) ? 
        this.configurations['short-urls'].rawShortWallet : 'https://4d5c.short.gy/nDVNA7';
    
  },
  mounted() {
    setTimeout(() => {
      if (this.cashback.message) {
        this.replaceGaps(this.cashback.message.text, 'receive');
      }
    }, 500);
  },
  data() {
    const hasReceiveMessage =
      this.cashback.message && this.cashback.message.type;
    return {
      environment: null,
      showCashbackReceive: hasReceiveMessage ? 'yes' : 'no',
      isWaitingSending: false,
      remainTime: 0,
      defaultShortenedUrl: '',
      testPhone: {
        ddd: '',
        number: '',
      },
      messages: {
        receive: {
          withGaps: hasReceiveMessage ? this.cashback.message.text : '',
          preview: '',
          final: '',
        },
      },
      cashbackValues: {
        percentage: this.cashback.percentage,
        message: hasReceiveMessage
          ? JSON.parse(JSON.stringify(this.cashback.message))
          : null,
      },
    };
  },
  methods: {
    toggleCashbackReceive(option) {
      this.showCashbackReceive = option;
      if (this.showCashbackReceive == 'yes') {
        this.cashbackValues.message = {
          type: '',
          text: '',
        };
      }

      this.emitChange();
    },
    emitChange() {
      this.$emit(
        'changed',
        {
          message:
            this.showCashbackReceive === 'yes'
              ? {
                  type: this.cashbackValues.message.type,
                  text: this.messages.receive.final,
                }
              : null,
        },
        this.showCashbackReceive
      );
    },
    formatPercent() {
      return `${+this.cashback.percentage}%`;
    },
    selectShippingMethod(value, type) {
      this.cashbackValues[type].type = value;
      this.emitChange();
    },
    formatCurrency(value) {
      const formated = parseFloat(value);

      return formated.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    replaceGaps(event, type) {
      const { percentage } = this.cashback;
      const hasValue = percentage ? `${percentage}` : '';

      const gapColors = event
        .replaceAll(
          '<link>',
          `<span style="color:#6161ff">${this.defaultShortenedUrl}</span>`
        )
        .replaceAll(
          '<beneficio>',
          `<span style="color:#6161ff">${this.formatPercent(hasValue)}</span>`
        )
        .replaceAll(
          '<benefício>',
          `<span style="color:#6161ff">${this.formatPercent(hasValue)}</span>`
        )
        .replaceAll(
          '<valorrecebido>',
          `<span style="color:#6161ff">${this.formatCurrency(1.41)}</span>`
        )
        .replace(/(<\/?(?:span)[^>]*>)|<[^>]+>/gi, '$1');

      const previewWithGapColors = document.querySelector(`.preview.${type}`);
      previewWithGapColors.innerHTML = `${gapColors}`;

      this.messages[type].preview = `${gapColors}`;
      this.messages[type].final = `${event}`.replaceAll(
        '<benefício>',
        '<beneficio>'
      );

      this.emitChange();
    },
    getTotalChars(type) {
      return this.messages[type].final.length;
    },
    getTotalSMS(type) {
      return Math.ceil(this.messages[type].final.length / maxCharsPerMessage);
    },
    async sendSmsTest() {
      this.isWaitingSending = true;
      const response = await TestShootingAPI.postSms(
        new TestShootingSmsBody({
          phone: `${this.testPhone.ddd}${this.testPhone.phone}`,
          message: this.messages['receive'].final,
          replacements: {
            url: { type: 'STANDARD' },
            beneficio: {
              type: 'CASHBACK_PROGRAM',
              value: this.cashback.percentage,
            },
            valorrecebido: 1.41,
          },
        })
      );

      if (response.getCode(500) != 200) {
        alert('Ocorreu um erro ao disparar a mensagem de teste');
        this.isWaitingSending = false;
        return;
      }

      this.remainTime = 180;
      const intervalId = setInterval(() => {
        this.remainTime--;
        if (this.remainTime == 0) {
          clearInterval(intervalId);
          this.isWaitingSending = false;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.use-of-tags {
  background: rgba(46, 52, 56, 0.5);
  border-radius: 10px;
  padding: 20px;
}

.use-of-tags-1 {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #aaaaab;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.use-of-tags-2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #aaaaab;
  background: rgba(46, 52, 56, 0.75);
  border-radius: 10px;
  padding: 20px 50px;
  margin-top: 15px;
}

.tag {
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #6161ff;
}

.count-characters {
  background: rgba(53, 61, 66, 0.33);
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.count-characters .number {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(177, 177, 178, 0.9);
}

.count-characters .type {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.85);
}

.count-characters .type:nth-child(2) {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid rgba(111, 118, 126, 0.35);
}

.preview {
  background: #222629;
  border-radius: 15px;
  padding: 15px;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #aaaaaa;
  min-height: 90px;
  word-break: break-all;
  white-space: break-spaces;
}

.enter-phone {
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.75);
  margin-bottom: 20px;
}

.fire-test-message {
  background: rgba(46, 52, 56, 0.3);
  border-radius: 10px;
  padding: 20px;
}
</style>
