<template>
  <div :class="!defaultCard ? '' : sortCard ? 'sort-card' : card ? 'card' : 'default-card'">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    props: {
      defaultCard: {type: Boolean, default: true},
      card: {type: Boolean, default: false},
      sortCard: {type: Boolean, default: false}
    },
  }
</script>
<style scoped>
.default-card {
  margin-bottom: 20px;
  background: var(--box);
  border-radius: 5px;
  padding: 20px;
}

.card {
  background: var(--box);
}

.drawer-content .card {
  padding: 30px 0 0;
  border-top: 2px dashed #ffffff17;
}

.drawer-content .default-card {
  padding: 30px 0;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  color: #999;
  position: relative;
  background: transparent;
  border-top: 2px dashed #ffffff17;
}

.sort-card {
  padding: 10px 0;
  border-bottom: 2px dashed #ffffff17;
}
</style>