<template>
  <div class="menu-mobile" :class="{ open: menuOpen }">
    <ul class="shortcuts" :class="getOS() === 'iOS' ? 'ios' : ''">
      <div class="visible">
        <li
          v-for="(link, i) in visibleShortcuts"
          :key="i"
          :class="{ active: isRoute(link) }"
          @click="menuOpen = false"
        >
          <router-link :to="{ name: link.name }">
            <i :class="`far ${link.icon}`" />
          </router-link>
        </li>
        <li @click="menuOpen = !menuOpen" class="open-menu">
          <div class="chevron">
            <i class="fas fa-chevron-up" :class="{ rotate: menuOpen }" />
          </div>
        </li>
      </div>

      <div class="hidden" :class="{ show: menuOpen }">
        <li
          v-for="(link, i) in hiddenShortcuts"
          :key="i"
          :class="{ active: isRoute(link) }"
          @click="menuOpen = false"
        >
          <router-link :to="{ name: link.name }">
            <i :class="link.icon" />
          </router-link>
        </li>

        <li>
          <a @click.prevent="logout()">
            <i class="far fa-power-off" />
          </a>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import SocketIO from 'easyjs/lib/Socket/SocketIO';

import { setXUserKey } from '@/plugins/account';

import getOS from '@/mixins/getOS.js';

export default {
  mixins: [getOS],
  data() {
    return {
      menuOpen: false,
      currentRoute: null,
      visibleShortcuts: [
        {
          name: 'dashboard',
          icon: 'fa-house-blank',
        },
        {
          name: 'database',
          icon: 'fa-database',
        },
        {
          name: 'eventos',
          icon: 'fa-list-timeline',
        },
        {
          name: 'performance',
          icon: 'fa-bullhorn',
        },
        {
          name: 'cashback',
          icon: 'fa-hand-holding-dollar',
        },
      ],
      hiddenShortcuts: [
        // {
        //   name: 'beneficios',
        //   icon: 'far fa-ticket-simple',
        // },
        {
          name: 'cashback',
          icon: 'far fa-hand-holding-dollar',
        },
        {
          name: 'publicos',
          icon: 'far fa-users',
        },
        {
          name: 'fontes',
          icon: 'far fa-share-nodes',
        },
        {
          name: 'minha-conta',
          icon: 'far fa-user',
        },
      ],
    };
  },
  methods: {
    isRoute(link) {
      return this.currentRoute === link.name;
    },
    logout() {
      setXUserKey();
      this.$store.dispatch('account/cleanUser');
      this.$router.push({ name: 'login' });
      SocketIO.close();
    },
  },
  created() {
    this.currentRoute = this.$router.currentRoute.name;
  },
  watch: {
    $route() {
      this.currentRoute = this.$router.currentRoute.name;
    },
  },
};
</script>

<style scoped>
.menu-mobile {
  background: var(--background);
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 998;
  width: 100%;
  padding: 0 15px;
}

.menu-mobile ul.shortcuts {
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 18px;
  background-color: var(--background);
  max-height: 75px;
  transition: all 0.5s;
}

.menu-mobile ul.shortcuts :is(.visible, .hidden) {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid rgba(111, 118, 126, 0.15);
}

.menu-mobile.open ul.shortcuts {
  max-height: 155px;
}

.menu-mobile .shortcuts li {
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menu-mobile .shortcuts li a {
  color: var(--icon-inactive);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
  border-radius: 10px;
  font-size: 15px;
}

.menu-mobile .shortcuts li.open-menu .chevron {
  border-left: 2px solid rgba(111, 118, 126, 0.25);
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--icon-inactive);
  margin-left: 10px;
  padding-left: 10px;
}

.menu-mobile:not(.open) .shortcuts.ios li.open-menu .chevron {
  margin-bottom: 10px;
  height: 30px;
}

.menu-mobile
  .shortcuts
  li.active
  a.router-link-exact-active.router-link-active {
  background: var(--default);
  color: #fff;
}

.menu-mobile .shortcuts li .chevron i.rotate {
  transform: rotate(180deg);
}
</style>
