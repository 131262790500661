<!--

== Base Radio

Props:

    > family
    Familia de radios a que este radio pertence.

    > label
    Texto que vai ao lado do radio. E só aparece, se o slot estiver vazio.


Obs:

- O slot incluso serve para incluir elementos, que ao clicados irão definir este radio como "checked". Caso não haja um conteúdo no slot, é necessário informar uma label.


-->

<template>
  <label
    :for="id"
    class="radio-box"
    :class="`design-${design} ${!enabled ? 'disabled' : ''} `"
  >
    <input
      type="radio"
      :key="id"
      :disabled="!enabled"
      :id="id"
      :name="family"
      v-bind="$attrs"
      :value="option"
      v-on="inputListeners"
      :checked="isChecked"
    />
    <div class="radio" :class="{ disabled: !enabled }">
      <span></span>
    </div>

    <div class="radio-content">
      <p
        v-if="!$slots.default"
        class="radio-label"
        :class="{ disabled: !enabled }"
      >
        {{ label }}
      </p>

      <!-- Conteúdo para ser clicado (Não obrigatório) -->
      <slot></slot>
    </div>
  </label>
</template>

<script>
import { baseEvents } from '@/mixins/baseEvents.js';

export default {
  mixins: [baseEvents],
  props: {
    family: { type: String, required: true },
    label: { type: String },
    option: { type: [String, Number, Boolean] },
    enabled: { type: Boolean, default: true },
    design: { type: String, default: 'one' },
    defaultValue: {},
  },
  data() {
    return {
      id: '',
    };
  },
  computed: {
    isChecked() {
      let checked = false;
      if (this.defaultValue) checked = true;
      if (String(this.option) === String(this.value)) checked = true;
      return checked;
    },
  },
  created() {
    // Gerando ID único
    this.id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  },
};
</script>

<style scoped>
input[type='radio'] {
  display: none;
}

.radio-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.radio-box.design-two {
  background: rgba(46, 52, 56, 0.65);
  border-radius: 10px;
  padding: 20px;
}

.radio-box.design-two.disabled {
  background: #272c31;
}

.radio-box.disabled {
  cursor: not-allowed;
}

.radio {
  border: 1px solid var(--checkbox);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.radio-box.design-one .radio.disabled,
.radio-box.design-one .radio-label.disabled {
  opacity: 0.45;
}

.radio-box.design-two .radio.disabled,
.radio-box.design-two .radio-label.disabled {
  color: #40464c;
  border-color: #40464c;
}

.coupon-radio .radio {
  position: absolute;
  right: 5px;
  top: 20px;
}

.radio span {
  width: 9px;
  height: 9px;
  background: white;
  border-radius: 100%;
  background: transparent;
}

input:checked + .radio span {
  background: var(--default);
}

input:checked + .radio {
  border-color: var(--default);
}

.radio-label {
  font-size: 1.2em;
  color: #aaaaab;
}
</style>
