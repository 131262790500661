<template>
  <div class="drawer-wrapper">
    <div
      class="drawer-fade"
      :class="[{ active: status }, `opacity-${opacity} ${direction}`]"
      @click="beforeClose($event, false)"
    />

    <div :class="[{ opened: status }, `drawer ${direction}`]">
      <div class="drawer-title" v-if="!search">
        <span
          v-if="goBack"
          class="back"
          @click="back ? prev() : beforeClose($event, true)"
        >
          <i class="far fa-chevron-left" />
        </span>

        <p class="title-text" v-if="title">{{ title }}</p>

        <span v-if="haveX" @click="beforeClose($event, true)" class="close">
          <i class="far fa-times" />
        </span>
      </div>

      <div
        class="drawer-content"
        :class="[
          { search: search },
          !(showFooter && $slots.footer) ? 'mb_0' : 'has-footer',
        ]"
      >
        <slot></slot>
      </div>

      <div v-if="showFooter && $slots.footer" class="drawer-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AppBridge from '@/plugins/app';
import store from '@/store';

export default {
  data() {
    return {
      status: false,
      showFooter: true,
    };
  },
  props: {
    open: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
    title: { type: String, default: '' },
    direction: { type: String, default: 'right' },
    opacity: { type: Number, default: 5 },
    search: { type: Boolean, default: false },
    haveX: { type: Boolean, default: true },
    closeValidation: { type: Function, default: null },
    closeInFade: { type: Boolean, default: true },
    goBack: { type: Boolean, default: false },
    back: { type: Boolean, default: false },
  },
  methods: {
    prev() {
      this.$emit('onBack');
    },
    async beforeClose(e, closeInX) {
      if (closeInX || this.$props.closeInFade) {
        if (!this.closeValidation) {
          this.status = false;
          this.$emit('onClose');
          return;
        }

        if (await this.closeValidation(e)) {
          this.status = false;
          this.$emit('onClose');
        }
      }
    },
  },
  mounted() {
    if (AppBridge.isApp()) {
      AppBridge.listen('keyboardDidShow', () => {
        this.showFooter = false;
      });

      AppBridge.listen('keyboardDidHide', () => {
        this.showFooter = true;
      });
    }
  },
  watch: {
    open() {
      this.status = true;
      store.commit('store/setHasDrawerOpened', true);
    },
    close() {
      this.status = false;
      store.commit('store/setHasDrawerOpened', false);
    },
  },
  computed: {
    ...mapState({
      hasDrawerOpened: (state) => state.store.hasDrawerOpened,
    }),
  },
};
</script>

<style scoped>
.drawer-wrapper {
  z-index: 1001;
}
.drawer-wrapper.active {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
}
.drawer {
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow: hidden;
  position: fixed;
  width: 100%;
  overflow-x: hidden;
  left: 0;
  top: 0;
  background: var(--box);
  transition: all 0.5s ease;
  z-index: 1002;
}
.drawer.center {
  transition: none;
}
.drawer .drawer-footer {
  background: var(--box);
  width: 100%;
  box-shadow: 0px 0px 6px #00000029;
  padding: 20px 15px;
  z-index: 1;
  position: absolute;
  bottom: 0;
}
.drawer-fade {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}
.drawer-fade.center {
  transition: none;
}
.drawer-fade.opacity-5 {
  background: rgba(0, 0, 0, 0.5);
}
.drawer-fade.opacity-8 {
  background: rgba(0, 0, 0, 0.8);
}
.drawer-fade.active {
  opacity: 1;
  pointer-events: unset;
}
.drawer .drawer-title {
  color: #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px 10px;
  min-height: 65px;
  position: relative;
}
.drawer.top .drawer-title {
  color: #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px;
  position: relative;
  margin: 0 20px;
  min-height: auto;
  border-bottom: 1px solid rgba(111, 118, 126, 0.25);
}
.drawer.center .drawer-title {
  background: rgba(46, 52, 56, 0.33);
  padding: 25px 20px;
  border-bottom: 1px dashed rgba(111, 118, 126, 0.25);
}
.drawer .drawer-title .title-text {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 0 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
}
.drawer.center .drawer-title .title-text {
  text-align: left;
  padding: 0;
}
.drawer .drawer-title .back {
  color: #6f767e;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  left: 20px;
}
.drawer .drawer-title .close {
  color: #6f767e;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  right: 20px;
}
.drawer.center .drawer-title .close {
  color: var(--icon-inactive);
}
.drawer.top {
  top: -100vh;
  bottom: auto;
  left: 0;
  right: 0;
  margin: auto;
  height: auto;
  border-radius: 10px;
}
.drawer.right {
  right: -100vw;
  height: 100%;
  left: auto;
}
.drawer.right.opened {
  right: 0;
}
.drawer.left {
  left: -100vw;
}
.drawer.left.opened {
  left: 0;
}
.drawer.top {
  top: -100vh;
}
.drawer.top.opened {
  top: 30px;
}
.drawer.bottom,
.drawer.center {
  height: auto;
}
.drawer.center {
  top: -100vh;
  margin: auto;
  height: fit-content;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 10px;
}
.drawer.center.opened {
  top: 0;
  bottom: 0;
}
.drawer .drawer-content {
  padding: 20px;
  overflow-x: hidden;
}
.drawer:is(.left, .right) .drawer-content {
  height: calc(100% - 85px);
  padding-bottom: 60px;
  margin-bottom: 90px;
}
.drawer:is(.left, .right) .drawer-content:not(.has-footer) {
  height: calc(100vh - 65px);
}

@media screen and (max-width: 991px) {
  .drawer:not(.center) .drawer-content {
    padding: 20px 15px 60px;
    max-height: -webkit-fill-available;
  }
  .drawer:is(.left, .right) .drawer-content {
    height: calc(100% - 175px);
  }
  .drawer.top {
    width: calc(100vw - 40px);
  }
  .drawer.center {
    margin-top: 15px;
    width: calc(100% - 30px);
  }
  .drawer.top.opened {
    top: 20px;
  }
  .drawer.top .drawer-content {
    padding-bottom: 20px;
  }
  .drawer .drawer-content.search {
    padding: 0;
  }
  .drawer.bottom {
    bottom: -100vh;
    top: auto;
  }
  .drawer.bottom.opened {
    bottom: 0;
  }
  .drawer.bottom .drawer-content {
    padding: 20px 15px 30px;
  }
  .drawer.bottom.opened .drawer-content {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 992px) {
  .drawer.bottom {
    transform: translateY(-100%);
    border-radius: 10px;
  }
  .drawer {
    width: 30%;
    min-width: 415px;
  }
  .drawer.top {
    min-width: 630px;
  }
  .drawer.center {
    min-width: 660px;
  }
  .drawer.bottom {
    right: 0;
    margin: auto;
  }
}
</style>
