<template>
  <div class="alert">
    <p>{{ this.msg }}</p>
    <div>
      <base-button
        :text="buttonText"
        v-if="type == 'alert'"
        @click="onConfirm(true)"
      />
      <div v-if="type == 'confirm'" class="d-flex">
        <base-button @click="onConfirm(true)" class="yes">{{
          confirmButtonText
        }}</base-button>
        <base-button @click="onConfirm(false)" design="two" class="no">
          {{ cancelButtonText }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';

import BaseButton from '@/components/Button';

export default Vue.extend({
  props: {
    msg: { default: '' },
    buttonText: { default: 'Fechar' },
    confirmButtonText: { default: 'Sim' },
    cancelButtonText: { default: 'Não' },
    type: { default: 'alert' },
    cb: null,
  },
  methods: {
    async onConfirm(confirmation = false) {
      await this.$emit('closeAlert');
      if (typeof this.cb === 'function') return this.cb(confirmation);
    },
  },
  components: {
    BaseButton,
  },
});
</script>

<style scoped>
.alert {
  margin-top: 5px;
}
.alert button {
  min-width: 150px;
}
.alert > p {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ebebeb;
  font-weight: 300;
}

.alert > div {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.alert .yes {
  margin-right: 20px;
}

.alert .yes,
.alert .no {
  width: 150px;
}
</style>
