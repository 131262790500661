<template>
  <div class="benefit-card">
    <div class="header">
      <div class="d-flex align-center">
        <div class="icon-gift">
          <i class="fal fa-gift" />
        </div>
        <span class="code">{{ benefit.code }}&nbsp;&nbsp;-</span>
        <span class="pin">{{ benefit.pin }}</span>
      </div>

      <div class="d-flex align-center">
        <base-button
          design="five mini icon"
          @click="$emit('openDrawer', benefit)"
        >
          <i class="far fa-qrcode" />
        </base-button>
        <div class="separator"></div>
        <base-button
          design="five mini icon"
          @click="
            changeRoute('detalhes-beneficio', {
              id: benefit.id,
              benefit: benefit,
            })
          "
        >
          <i class="far fa-chart-line" />
        </base-button>
      </div>
    </div>

    <div class="content">
      <div class="infos">
        <div class="info">
          <p>Desconto</p>
          <span>{{ getBenefitValue(benefit.type) }}</span>
        </div>

        <div class="info">
          <p>Pedido mínimo</p>
          <span>{{ Utils.convertMoney(benefit.min_order) }}</span>
        </div>
      </div>

      <div class="infos">
        <div class="info">
          <p>Expiração</p>
          <span>{{
            benefit.expiration_days ? benefit.expiration_days + ' dias' : '-'
          }}</span>
        </div>

        <div class="info">
          <p>Tipo de uso</p>
          <span>{{ getUseType(benefit.use_type) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils';

import BaseButton from '@/components/Button';

const benefitTypes = {
  FREE_DELIVERY: 'FREE_DELIVERY',
  CASH_DISCOUNT: 'CASH_DISCOUNT',
  PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
  CASHBACK: 'CASHBACK',
};

const useTypes = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  BOTH: 'BOTH',
};

export default {
  data() {
    return {
      Utils,
    };
  },
  components: {
    BaseButton,
  },
  props: {
    benefit: { type: Object, default: null, required: true },
  },
  methods: {
    getBenefitValue(benefitType) {
      switch (benefitType) {
        case benefitTypes.PERCENTAGE_DISCOUNT:
        case benefitTypes.CASHBACK:
          return `${this.benefit.value}%`;
        case benefitTypes.FREE_DELIVERY:
          return 'Entrega grátis';
        case benefitTypes.CASH_DISCOUNT:
        default:
          return Utils.convertMoney(this.benefit.value);
      }
    },
    getBenefitType(benefitType) {
      switch (benefitType) {
        case benefitTypes.FREE_DELIVERY: {
          return 'Entrega grátis';
        }
        case benefitTypes.CASH_DISCOUNT: {
          return 'Desconto em R$';
        }
        case benefitTypes.PERCENTAGE_DISCOUNT: {
          return 'Desconto em %';
        }
        case benefitTypes.CASHBACK: {
          return 'Cashback';
        }
      }
    },
    getUseType(useType) {
      switch (useType) {
        case useTypes.ONLINE: {
          return 'Delivery';
        }
        case useTypes.OFFLINE: {
          return 'Consumo no local';
        }
        case useTypes.BOTH: {
          return 'Consumo no local e delivery';
        }
      }
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped>
.benefit-card .header {
  background: rgba(30, 33, 36, 0.37);
  border-radius: 10px;
  padding: 5px 15px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.benefit-card .header .icon-gift {
  background: rgba(30, 33, 36, 0.45);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 42px;
  height: 42px;
}

.benefit-card .header .icon-gift i {
  color: #6f767e;
  font-size: 12px;
}

.benefit-card .header .code {
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #cccccc;
}

.benefit-card .header .pin {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #6161ff;
  margin-left: 5px;
}

.benefit-card .header .separator {
  height: 22px;
  width: 1px;
  background: rgba(111, 118, 126, 0.2);
  margin: 0 10px;
}

.benefit-card .content .infos {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
}

.benefit-card .content .infos:first-child {
  padding-top: 25px;
  margin-top: 0;
}

.benefit-card .content .infos:not(:last-child) {
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  margin-bottom: 15px;
}

.benefit-card .content .infos .info {
  display: flex;
  flex-direction: column;
}

.benefit-card .content .infos .info:last-child {
  align-items: flex-end;
}

.benefit-card .content .infos .info strong {
  font-weight: bold;
  color: var(--default);
}

.benefit-card .content .infos .info p {
  font-weight: 500;
  color: #cccccc;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
}

.benefit-card .content .infos .info span {
  color: rgba(204, 204, 204, 0.65);
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
}

.benefit-card .content .infos .info:last-child span {
  text-align: right;
}

.benefit-card .funnel {
  margin: 0 -10px -10px;
  background: rgba(46, 52, 56, 0.25);
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .benefit-card .funnel {
    flex-direction: column;
  }
}

.benefit-card .funnel .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .benefit-card .funnel .item:nth-child(2) {
    border-left: 1px solid rgba(111, 118, 126, 0.15);
    border-right: 1px solid rgba(111, 118, 126, 0.15);
    padding: 0 45px;
    margin: 0 40px;
  }
}

@media screen and (max-width: 991px) {
  .benefit-card .funnel .item:nth-child(2) {
    border-top: 1px solid rgba(111, 118, 126, 0.15);
    border-bottom: 1px solid rgba(111, 118, 126, 0.15);
    padding: 15px 0;
    margin: 15px 0;
  }
}

.benefit-card .funnel .item p {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: rgba(204, 204, 204, 0.75);
  margin-bottom: 10px;
}

.benefit-card .funnel .item span {
  background: rgba(46, 52, 56, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 15px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(204, 204, 204, 0.65);
}

.benefit-card .funnel .item span i {
  color: var(--default);
  margin-left: 5px;
}
</style>
