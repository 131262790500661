<template>
  <div :class="`the-label design-${design}`">
    <p v-if="text">{{ text }}</p>
    <slot v-else></slot>
  </div>
</template>
<script>
export default {
  props: {
    text: { type: String },
    design: { type: String, default: 'one' },
  },
};
</script>
<style scoped>
.the-label {
  color: var(--label-primary);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
}

.the-label.design-two {
  color: rgba(170, 170, 171, 0.75);
}
</style>
