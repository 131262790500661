<!--

== Base Textarea

Props:

    > family
    Familia de radios a que este radio pertence.

-->

<template>
  <div class="textarea">
    <div class="label-float">
      <textarea
        :id="id"
        ref="textarea"
        :placeholder="label"
        v-bind="$attrs"
        :value="value"
        v-on="inputListeners"
        :maxlength="maxLength"
        :class="`${!!design ? `design-${design}` : ''} ${
          noResize ? 'resize-none' : ''
        }`"
        :style="minHeight ? `min-height: ${minHeight}px` : ''"
      ></textarea>
      <label :for="id">{{ label }}</label>
    </div>
  </div>
</template>

<script>
import { baseEvents } from '@/mixins/baseEvents.js';

export default {
  mixins: [baseEvents],
  props: {
    label: { type: String },
    design: { type: String, default: 'two' },
    rows: { type: Number },
    minHeight: { type: Number },
    noResize: { type: Boolean, default: false },
  },
  data() {
    return {
      id: '',
      height: 0,
      lines: 1,
      length: 0,
      maxLength: 999,
      limitReached: false,
      scrollHeight: 0,
    };
  },
  created() {
    // Gerando ID único
    this.id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  },
  updated() {
    this.resize();
  },
  methods: {
    update() {
      // atualizando a quantidade de caracteres
      this.length = this.$refs.textarea.value.length;
      if (this.$refs.textarea.value.length === 0) this.lines = 1;
      this.setRowsAmount();

      // não permitindo a digitação se a atingiu o limite de linhas
      this.$refs.textarea.readOnly = this.limitReached;
      this.maxLength = this.limitReached
        ? this.$refs.textarea.value.length
        : 999;

      if (this.$refs.textarea.readOnly) {
        this.$refs.textarea.addEventListener('keyup', this.setReadonly, false);
      } else {
        this.$refs.textarea.removeEventListener(
          'keyup',
          this.setReadonly,
          false
        );
      }
    },
    resize() {
      // atualizando a altura
      this.handleHeightChange(this.$refs.textarea.scrollHeight);
      this.$refs.textarea.style.height = `${this.height}px`;

      this.limitReached = this.isLimitReached();

      this.update();
    },
    handleHeightChange(scrollHeight) {
      if (this.height !== scrollHeight) {
        if (this.height < scrollHeight) this.lines += 1;
        else this.lines -= 1;

        this.height = scrollHeight;
      }
    },
    setRowsAmount() {
      const padding = window
        .getComputedStyle(this.$refs.textarea, null)
        .getPropertyValue('padding-top')
        .replace('px', '');
      const fontSize = window
        .getComputedStyle(this.$refs.textarea, null)
        .getPropertyValue('font-size')
        .replace('px', '');

      const scrollHeight = this.$refs.textarea.scrollHeight;
      const rowsAmount =
        (scrollHeight - parseInt(padding, 10) * 2) /
        (parseInt(fontSize, 10) + 2);

      const rows =
        this.value && this.value.length === 0
          ? 1
          : rowsAmount < 1
          ? 1
          : Math.trunc(rowsAmount);
      this.lines = rows;
    },
    isLimitReached() {
      return this.lines > this.rows;
    },
    setReadonly(event) {
      this.$refs.textarea.readOnly = !(event.code === 'Backspace');
    },
  },
};
</script>

<style scoped>
.textarea {
  padding-top: 10px;
}

.label-float textarea.resize-none {
  resize: none;
}

.label-float {
  position: relative;
}

.label-float textarea {
  background: transparent;
  border: 1px solid var(--input-border);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  min-height: 45px;
  color: rgba(170, 170, 170, 0.75);
  resize: vertical;
  overflow: hidden;
}

.label-float textarea.design-one {
  background-color: #3a3a40;
  border-color: #3a3a40;
}

.label-float textarea.design-one:not(:placeholder-shown),
.label-float textarea.design-one:focus {
  background: transparent;
}

.label-float textarea.design-two {
  background: transparent;
  border-color: var(--input-border);
}

.label-float textarea:focus,
.label-float textarea[class*='design']:focus {
  outline: 0;
  border-color: var(--default);
}

.label-float:not(.placeholder) textarea::-webkit-input-placeholder {
  opacity: 0;
}

.label-float:not(.placeholder) textarea::-moz-placeholder {
  opacity: 0;
}

.label-float:not(.placeholder) textarea:-ms-input-placeholder {
  opacity: 0;
}

.label-float:not(.placeholder) textarea::-ms-input-placeholder {
  opacity: 0;
}

.label-float:not(.placeholder) textarea::placeholder {
  opacity: 0;
}

.label-float.placeholder textarea::-webkit-input-placeholder {
  color: var(--input-text);
  font-size: 12px;
}

.label-float.placeholder textarea::-moz-placeholder {
  color: var(--input-text);
  font-size: 12px;
}

.label-float.placeholder textarea:-ms-input-placeholder {
  color: var(--input-text);
  font-size: 12px;
}

.label-float.placeholder textarea::-ms-input-placeholder {
  color: var(--input-text);
  font-size: 12px;
}

.label-float.placeholder textarea::placeholder {
  color: var(--input-text);
  font-size: 12px;
}

.label-float label {
  position: absolute;
  padding: 5px 10px;
  left: 5px;
  top: 7px;
  font-size: 12px;
  -webkit-transition: 0.2s all ease-in-out, background-color 0s;
  -o-transition: 0.2s all ease-in-out, background-color 0s;
  transition: 0.2s all ease-in-out, background-color 0s;
  background-color: var(--box);
  color: var(--input-text);
  border-radius: 5px;
  pointer-events: none;
}

.label-float textarea + label {
  color: var(--input-text);
  display: flex;
  align-items: center;
  top: 11px;
}

.label-float textarea:focus + label,
.label-float textarea:not(:placeholder-shown) + label,
.label-float textarea:not(:empty) + label {
  top: -12px;
  left: 5px;
  background-color: var(--box);
}

.label-float textarea.design-one:focus + label,
.label-float textarea.design-one:not(:placeholder-shown) + label,
.label-float textarea.design-one:not(:empty) + label {
  background-color: var(--background);
}

.label-float textarea.design-two:focus + label,
.label-float textarea.design-two:not(:placeholder-shown) + label,
.label-float textarea.design-two:not(:empty) + label {
  background-color: var(--box);
  color: rgba(170, 170, 170, 0.8);
}

.label-float textarea + label:empty {
  display: none;
}

.max {
  padding: 10px 12px;
  font-size: 1.2em;
  background: #f2f2f2;
  position: absolute;
  bottom: 2px;
  right: 1px;
  border-radius: 5px 0 5px 0;
  color: #999;
}

.max + textarea {
  padding-bottom: 48px;
}
</style>
