<!--

== Base Input

Props:

    > type
    Tipo do input. Póssiveis: text, number, cel, email, password.

    > label
    Texto descritivo do input, utilizado no label-float.

    > mask
    Usado para passar mascaras no padrão vue-mask.

    > moneyMask
    Usado para passar mascaras no padrão vue-money.

    > masked
    Usado para informar se o v-model deve retornar o valor com mascara ou não.

    > placeholder
    Utilizado para quando não houver label. Utiliza-lo fará com que a props label seja ignorada.

    > max
    Máximo de caracteres

-->

<template>
  <div class="input" :class="`${hasSuffix ? 'suffix' : ''}`">
    <div
      class="label-float"
      :class="{ placeholder: placeholder, invalid: !valid }"
    >
      <div
        class="prefix"
        v-if="prefix"
        ref="prefix"
        :style="`width: ${prefixWidth}px`"
      >
        {{ prefix }}
      </div>
      <input
        ref="inputField"
        :class="`${!!design ? `design-${design}` : ''} ${
          !!search ? 'search' : ''
        } ${!!center ? 'center' : ''}`"
        :id="id"
        :type="!!showPassword ? 'text' : type"
        :placeholder="placeholder ? placeholder : label"
        v-bind="$attrs"
        :maxlength="max"
        v-model="insideValue"
        v-on="inputListeners"
        v-money="moneyAttr"
        v-if="!!moneyAttr"
        @keyup.enter="search ? onEnter($event) : null"
      />
      <input
        ref="inputField"
        :class="`${!!design ? `design-${design}` : ''} ${
          !!search ? 'search' : ''
        } ${!!center ? 'center' : ''} ${hasSuffix ? 'suffix' : ''}`"
        :id="id"
        :type="!!showPassword ? 'text' : type"
        :placeholder="placeholder ? placeholder : label"
        v-bind="$attrs"
        :value="value"
        v-on="inputListeners"
        v-mask="maskDefinition"
        v-else
        @keyup.enter="search ? onEnter($event) : null"
      />
      <div v-if="!!search" class="search-field">
        <i class="far fa-search"></i>
      </div>
      <div class="records" v-if="!!search && records">
        {{ records }} {{ recordsText }}
      </div>
      <label v-if="!placeholder" :for="id">{{ label }}</label>
      <div v-if="type == 'password'" class="eye" @click="toggleEye()">
        <i class="far" :class="!!showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
      </div>
    </div>
    <div v-if="options && options.length > 0" class="input-options">
      <ul>
        <li
          v-for="(option, optionIndex) in options"
          :key="optionIndex"
          @click="optionSelected(option, optionIndex)"
        >
          {{ option.title }}
        </li>
      </ul>
    </div>
    <div v-if="optload" class="options-loading">
      <ul>
        <li v-for="li in 2" :key="li">
          <div></div>
          <div></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { baseEvents } from '@/mixins/baseEvents.js';
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';

export default {
  mixins: [baseEvents],
  directives: {
    mask: (el, binding) => {
      if (!binding.value) return;
      mask(el, binding);
    },
    money: VMoney,
  },
  inheritAttrs: false,
  data() {
    let insideValue = null;
    const moneyAttr = this.moneyMask
      ? {
          decimal: ',',
          thousands: this.thousandSeparator ? '.' : '',
          ...(this.moneyMask === true ? {} : this.moneyMask),
        }
      : false;

    if (moneyAttr) {
      insideValue = (+this.value).toFixed(this.moneyMask.precision || 2);
    } else {
      insideValue = this.value;
    }

    return {
      id: '',
      insideValue,
      moneyAttr,
      nomask: {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ },
        },
      },
      showPassword: false,
    };
  },
  props: {
    type: { type: [String, Array], default: 'text' },
    label: String,
    mask: String | Array,
    moneyMask: { type: [Boolean, Object], default: false },
    thousandSeparator: { type: Boolean, default: true },
    options: { type: [Array, Boolean], default: false },
    optload: { type: Boolean, default: false },
    masked: Boolean,
    placeholder: String,
    valid: { type: Boolean, default: true },
    max: { default: 20 },
    search: { type: Boolean, default: null },
    design: { type: String, default: 'two' },
    activeMask: { type: Boolean, default: true },
    prefixWidth: { type: Number, default: 0 },
    prefix: { type: String, default: '' },
    hasSuffix: { type: Boolean, default: false },
    records: { type: Number, default: 0 },
    recordsText: { type: String, default: 'registros' },
    center: { type: Boolean, default: false },
  },
  computed: {
    maskDefinition() {
      let definition = null;

      if (this.mask) {
        definition = this.mask;
      } else if (!this.activeMask) {
        definition = '';
      } else {
        definition = this.nomask;
      }
      return definition;
    },
  },
  created() {
    // Gerando ID único
    this.id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  },
  mounted() {
    if (this.$refs.prefix)
      this.$refs.inputField.style.paddingLeft = `${
        this.$refs.prefix.clientWidth + 21
      }px`;
  },
  methods: {
    onEnter(event) {
      this.$emit('search', event.target.value);
    },
    toggleEye() {
      this.showPassword = !this.showPassword;
    },
    focus() {
      this.$refs.inputField.focus();
    },
    blur() {
      this.$refs.inputField.blur();
    },
    optionSelected(option, optionIndex) {
      this.$emit('optionSelected', option, optionIndex);
    },
    unmask(maskedValue, mask) {
      if (this.moneyMask) {
        const negative = maskedValue.indexOf('-') >= 0 ? -1 : 1;

        const regex = /\D+/g;
        let float = null;
        if (regex.test(maskedValue)) {
          const numbers = `${maskedValue}`.replace(regex, '') || '0';
          const exp = Math.pow(10, mask.precision || 0);
          float = parseFloat(numbers) / exp;
        } else {
          float = parseFloat(maskedValue);
        }

        const currency = float.toFixed(mask.precision || 0);
        return `${(parseFloat(currency) * negative).toFixed(
          mask.precision || 0
        )}`;
      } else {
        let unmaskedValue = '';
        let defaultTokens = ['#', 'X', 'S', 'A', 'a', '!'];
        let isToken;

        if (Array.isArray(mask)) {
          let masks = mask
            .filter((m) => m.length >= maskedValue.length)
            .sort((a, b) => a.length <= b.length);
          mask = masks.length > 0 ? masks[0] : mask[0];
        }

        for (let i = 0; i < maskedValue.length; i++) {
          isToken = false;
          for (let j = 0; j < defaultTokens.length; j++) {
            if (mask[i] == defaultTokens[j]) {
              isToken = true;
            }
          }
          if (isToken) {
            unmaskedValue += maskedValue[i];
          }
        }
        return unmaskedValue;
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler(newV) {
        if (this.moneyMask && !this.masked) {
          const isDecimal = String(newV).includes('.');
          this.insideValue = isDecimal ? parseFloat(newV).toFixed(2) : newV;
        }
      },
    },
    insideValue: {
      deep: true,
      handler(newV) {
        if (this.moneyMask && !this.masked) {
          this.$emit('input', this.unmask(`${newV}`, this.moneyMask));
        }
      },
    },
  },
};
</script>

<style scoped>
.eye {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.eye i {
  color: var(--icon-inactive);
  font-size: 12px;
}

.prefix {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  color: rgba(170, 170, 170, 0.8);
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  padding-left: 15px;
  padding-right: 15px;
  margin: auto 0;
}

.search-field {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 10px 0 15px;
}

.search-field i {
  font-size: 15px;
  color: var(--icon-inactive);
}

.search-records i {
  font-size: 15px;
  color: var(--icon-inactive);
}

.input.search-mobile {
  padding: 0;
  margin: 0;
}

.input.search-mobile input {
  border: none;
}

.input {
  padding-top: 10px;
  margin-bottom: 15px;
}

.label-float {
  position: relative;
}

.label-float input {
  background: transparent;
  border: 1px solid var(--input-border);
  padding: 10px 15px;
  min-height: 47px;
  border-radius: 10px;
  width: 100%;
  font-size: 12px;
  color: #ccc;
}

.label-float input.center {
  text-align: center;
}

.label-float.placeholder input.search {
  padding-left: 45px;
  padding-right: 120px;
}

.input.suffix {
  padding-top: 0;
}

.input.suffix .label-float input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.label-float input.design-one {
  background: transparent;
  border-color: rgba(72, 81, 89, 0.75);
}

.label-float input.design-one:not(:placeholder-shown),
.label-float input.design-one:focus {
  background: transparent;
}

.label-float input.design-two {
  background: transparent;
  border-color: var(--input-border);
}

.label-float input.design-four {
  background: #2a2f33;
  border-color: var(--input-border);
}

.label-float input.design-three + label {
  background: #2a3034;
}

.label-float input.design-search {
  background: #252a2e8c;
  border-color: transparent;
}

.label-float input.design-search-2 {
  background: rgba(46, 52, 56, 0.34);
  border-color: transparent;
}

.label-float:not(.placeholder) input::-webkit-input-placeholder {
  opacity: 0;
}
.label-float:not(.placeholder) input::-moz-placeholder {
  opacity: 0;
}
.label-float:not(.placeholder) input:-ms-input-placeholder {
  opacity: 0;
}
.label-float:not(.placeholder) input::-ms-input-placeholder {
  opacity: 0;
}
.label-float:not(.placeholder) input::placeholder {
  opacity: 0;
}

.label-float.placeholder input::-webkit-input-placeholder {
  color: var(--input-text);
  font-size: 12px;
}
.label-float.placeholder input::-moz-placeholder {
  color: var(--input-text);
  font-size: 12px;
}
.label-float.placeholder input:-ms-input-placeholder {
  color: var(--input-text);
  font-size: 12px;
}
.label-float.placeholder input::-ms-input-placeholder {
  color: var(--input-text);
  font-size: 12px;
}
.label-float.placeholder input::placeholder {
  color: var(--input-text);
  font-size: 12px;
}

.label-float label {
  position: absolute;
  padding: 5px 10px;
  left: 5px;
  color: var(--input-text);
  display: flex;
  align-items: center;
  top: 11px;
  font-size: 12px;
  -webkit-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  background-color: var(--box);
  border-radius: 5px;
  pointer-events: none;
}

.label-float input.design-one + label {
  background-color: transparent;
}

.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label,
.label-float input:not(:empty) + label {
  top: -12px;
  left: 5px;
  background-color: var(--box);
}

.label-float input.design-three:focus + label,
.label-float input.design-three:not(:placeholder-shown) + label,
.label-float input.design-three:not(:empty) + label {
  background-color: #2b3135;
}

.label-float input.design-one:focus + label,
.label-float input.design-one:not(:placeholder-shown) + label,
.label-float input.design-one:not(:empty) + label {
  background-color: var(--background);
  color: rgba(170, 170, 170, 0.8);
}

.label-float input.design-two:focus + label,
.label-float input.design-two:not(:placeholder-shown) + label,
.label-float input.design-two:not(:empty) + label {
  background-color: var(--box);
  color: rgba(170, 170, 170, 0.8);
}

.label-float input + label:empty {
  display: none;
}

.label-float input:focus,
.label-float.placeholder input.search:focus,
.label-float input[class*='design']:focus {
  outline: 0;
  border-color: var(--default);
}

.label-float input:focus + div > i,
.label-float.placeholder input.search:focus + div > i,
.label-float input[class*='design']:focus + div > i {
  color: var(--default);
}

.label-float input:disabled {
  cursor: not-allowed;
  opacity: 0.45;
}

.label-float.invalid input {
  border: 1px solid #9f3041;
}

.label-float.invalid input + label {
  color: #aaaaaaf2;
}

.input-options {
  border: 2px solid #4c4e51;
  border-top: 0;
  cursor: pointer;
}

.input-options li {
  padding: 18px;
  font-size: 1.2em;
  color: #999;
}

.input-options li:not(:last-child) {
  border-bottom: 2px solid #4c4e51;
}

.input-options li:hover {
  filter: brightness(1.1);
}

.options-loading {
  margin-top: 10px;
}

.options-loading li {
  height: 50px;
  width: 100%;
  border: 1px solid var(--input-border);
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.options-loading li:first-child {
  border-radius: 10px 10px 0 0;
}
.options-loading li:last-child {
  border-radius: 0 0 10px 10px;
}

.options-loading li div {
  height: 5px;
  width: 35%;
  -webkit-animation-name: example;
  animation-name: example;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-direction: alternate-reverse;
}

.options-loading li div:first-child {
  width: 15px;
  margin-right: 10px;
}

@-webkit-keyframes example {
  from {
    background-color: #4c4e51;
  }
  to {
    background-color: #3f4246;
  }
}

@keyframes example {
  from {
    background-color: #4c4e51;
  }
  to {
    background-color: #3f4246;
  }
}

/* Casos especificos */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.records {
  padding: 5px 15px;
  border-radius: 10px;
  background: rgba(28, 31, 34, 0.35);
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  height: 25px;
  color: rgba(204, 204, 204, 0.45);
  font-size: 12px;
  line-height: 14px;
}

.design-search-2 ~ .records {
  background: rgba(46, 52, 56, 0.65);
}

input[type='time'] {
  text-align: center;
}

input[type='date'] {
  text-align: center;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
