<!--

== Base Empty

Props:

    > image
    Caminho da imagem, caso a prop não seja passada será setada uma imagem padrão.

    > title
    Título.

    > message
    Texto de descrição.

    > buttonText
    Texto do botão, caso não seja passada a prop o botão não será exibido.

-->

<template>
  <div class="empty-container">
    <div v-if="$slots.image">
      <slot name="image"></slot>
    </div>

    <img v-else src="@/assets/img/Dominio.svg" alt="Vazio" />
    <h1>{{ this.title }}</h1>
    <p>{{ this.message }}</p>
    <button v-if="buttonText" @click="execute">{{ this.buttonText }}</button>
  </div>
</template>

<script>
export default {
  props: {
    image: { type: Boolean, default: true },
    title: String,
    message: String,
    buttonText: { type: String },
  },
  methods: {
    execute() {
      this.$emit('onClick');
    },
  },
};
</script>

<style scoped>
.empty-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 200;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-up);
  padding: 0 15px;
  font-weight: 500;
}

.empty-container img {
  width: 160px;
  max-width: 100%;
}

.empty-container i {
  font-size: 70px;
  color: #6f767e;
  margin-bottom: 5px;
}

.empty-container h1 {
  font-size: 20px;
  line-height: 24px;
  color: var(--label-secondary);
  font-family: 'Rubik-Regular', sans-serif;
  margin: 20px 0;
  text-align: center;
}

.empty-container p {
  font-size: 18px;
  color: #aaaaab;
  opacity: 0.6;
  font-family: 'Lato-Regular', sans-serif;
  text-align: center;
}

.empty-container button {
  border: none;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  color: #fff;
  background: var(--default);
  border-radius: 10px;
  padding: 15px 45px;
  margin-top: 35px;
}

.empty-container button:hover {
  background: var(--default-hover);
}

.empty-container button:focus,
.empty-container button:active {
  outline: none;
}

@media only screen and (max-width: 991px) {
  .empty-container {
    background: var(--background);
  }
  .empty-container h1 {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 15px;
  }

  .empty-container img {
    width: 120px;
  }

  .empty-container p {
    font-size: 14px;
    line-height: 16px;
    padding: 0 15px;
  }

  .empty-container button {
    margin-top: 30px;
  }
}
</style>
