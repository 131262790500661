<template>
  <div class="menu-desk">
    <div class="topper">
      <div class="logo">
        <img
          class="img-fluid"
          src="@/assets/img/menu-logo.png"
          alt="Logo Olga"
        />
      </div>
    </div>

    <ul class="itens-desk">
      <li v-for="(link, i) in links" :key="i" :data-tooltip="link.tooltip">
        <router-link :to="{ name: link.name }">
          <i :class="link.icon" />
        </router-link>
      </li>
      <li data-tooltip="Sair">
        <a @click="logout()"><i class="far fa-power-off" /></a>
      </li>
    </ul>
  </div>
</template>

<script>
import { setXUserKey } from '@/plugins/account';

export default {
  data() {
    return {
      links: [
        {
          name: 'dashboard',
          tooltip: 'Dashboard',
          icon: 'far fa-house-blank',
        },
        {
          name: 'database',
          tooltip: 'Base de dados',
          icon: 'far fa-database',
        },
        {
          name: 'eventos',
          tooltip: 'Eventos',
          icon: 'far fa-list-timeline',
        },
        {
          name: 'performance',
          tooltip: 'Campanhas',
          icon: 'far fa-bullhorn',
        },
        {
          name: 'cashback',
          tooltip: 'Cashback',
          icon: 'far fa-hand-holding-dollar',
        },
        {
          name: 'beneficios',
          tooltip: 'Benefícios avulsos',
          icon: 'far fa-ticket-simple',
        },
        {
          name: 'publicos',
          tooltip: 'Públicos',
          icon: 'far fa-users',
        },
        {
          name: 'fontes',
          tooltip: 'Unidades',
          icon: 'far fa-share-nodes',
        },
        {
          name: 'minha-conta',
          tooltip: 'Minha conta',
          icon: 'far fa-user',
        },
      ],
    };
  },
  methods: {
    logout() {
      setXUserKey();
      this.$store.dispatch('account/cleanUser');
      this.$router.push({ name: 'login' });
    },
  },
  created() {
    this.currentRoute = this.$router.currentRoute.name;
  },
  watch: {
    $route() {
      this.currentRoute = this.$router.currentRoute.name;
    },
  },
};
</script>

<style scoped>
.menu-desk {
  background: var(--background);
  height: 100vh;
  width: 60px;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  padding-top: 15px;
}

.logo img {
  width: 34px;
}

.notification {
  width: 19px;
  height: 19px;
  background: var(--default);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: -3px;
  top: 0;
}

.update-later {
  position: absolute;
  bottom: 25px;
  cursor: pointer;
  color: var(--default);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-later .pulse {
  background-color: #ff33824f;
  filter: blur(5px);
  display: block;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  animation: pulse 2.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(0);
  }
}

.topper {
  padding-bottom: 15px;
  border-bottom: 1px solid #202426;
  width: calc(100% - 10px);
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itens-desk {
  list-style: none;
  font-size: 15px;
  width: 100%;
}

.itens-desk li {
  position: relative;
}

.itens-desk li a {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.itens-desk li a {
  color: var(--icon-inactive);
  text-decoration: none;
}

.itens-desk li:hover a {
  color: var(--default);
}

.itens-desk li a.router-link-exact-active.router-link-active {
  background-color: var(--default);
  color: #fff;
}

.itens-desk
  li
  a.router-link-exact-active.router-link-active[href*='configuracoes']
  .notification {
  display: none;
}

.itens-desk li a[href='/'] .notification {
  display: none;
}

.store-details {
  background: #2a2c2f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.store-details i {
  font-size: 12px;
  color: var(--icon-inactive);
}

.status-store {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.status-store > .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  filter: blur(2px);
}

.status-store > .circle + i {
  width: 9px;
  height: 9px;
  position: absolute;
}

.status-store.open {
  background-color: #0dc59d1a;
}
.status-store.open > .circle {
  background-color: #0dc59d78;
}
.status-store.open > .circle + i {
  color: var(--success);
}
.status-store.close,
.status-store.pause,
.status-store.loading {
  background-color: transparent;
}
.status-store.close > .circle {
  background-color: #e2556963;
}
.status-store.close > .circle + i {
  color: #e25569;
}
.status-store.pause > .circle {
  background-color: #e2556963;
}
.status-store.pause > .circle + i {
  color: #e25569;
}

.tooltip-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 5px;
  min-height: 36px;
}

[data-tooltip]:after,
[data-tooltip]:before {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: auto;
  right: auto;
  box-sizing: border-box;
  transition: all 0.25s ease;
}
[data-tooltip]:after {
  content: attr(data-tooltip);
  background: #414147;
  top: 0;
  padding: 0.5rem 20px;
  color: #fff;
  line-height: 1.5;
  font-size: 12px;
  left: 80px;
  text-align: center;
  border-radius: 5px;
  white-space: nowrap;
}
[data-tooltip]:before {
  content: '';
  top: 10px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #414147;
  left: auto;
  right: -20px;
  margin: auto;
  width: 20px;
}
[data-tooltip]:hover:after,
[data-tooltip]:hover:before {
  visibility: visible;
  opacity: 1;
}
</style>
