<template>
  <ul class="breadcrumb">
    <li
      v-for="(breadcrumb, i) in items"
      :key="i"
      @click="i !== items.length - 1 && changeRoute(breadcrumb.link)"
      :class="{ 'current-page': i === items.length - 1 }"
    >
      <i class="fal fa-chevron-right" v-if="i >= 1" />
      {{ breadcrumb.name }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: { type: Array, default: () => [] },
  },
  methods: {
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  list-style: none;
  margin-top: 15px;
}

.breadcrumb li {
  color: #999999a6;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Rubik', sans-serif;
}

.breadcrumb li:not(.current-page) {
  cursor: pointer;
}

.breadcrumb li:not(.current-page):hover {
  opacity: 0.8;
}

.breadcrumb li.current-page {
  color: #c9c9c9;
  opacity: 0.8;
  font-weight: 400;
}

.breadcrumb li i {
  color: #999999a6;
  margin-left: 10px;
  padding-right: 10px;
  font-size: 9px;
}

@media only screen and (max-width: 991px) {
  .breadcrumb li {
    font-size: 14px;
    line-height: 17px;
  }
}
</style>
