<template>
  <base-input
    ref="input"
    :label="!placeholder ? placeholder : ''"
    :placeholder="placeholder"
    v-model="addressString"
    :options="addressOptions"
    :optload="searching"
    @keyup="searchStringAddress"
    @optionSelected="onAddressSelected"
    :design="`${!!design ? `${design}` : ''}`"
    :search="search"
    :valid="valid"
    :records="records"
    :recordsText="recordsText"
  ></base-input>
</template>
<script>
import BaseInput from '@/components/Input';
import AddressAPI from '@/api/address';

export default {
  components: { BaseInput },
  props: {
    placeholder: { type: String, default: 'Buscar endereço e número' },
    search: Boolean,
    design: String,
    value: String,
    valid: { type: Boolean, default: true },
    records: { type: Number, default: 0 },
    recordsText: { type: String, default: 'registros' },
  },
  data() {
    return {
      sessionToken: null,
      addressString: null,
      addressOptions: [],
      lastScheduledSearch: null,
      searching: false,
    };
  },
  mounted() {
    this.addressString = this.value;
  },
  methods: {
    generateNewSessionToken() {
      const s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);

      this.sessionToken = `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${
        s4() + s4() + s4()
      }`;
    },
    searchStringAddress(e) {
      if (!this.sessionToken) {
        this.generateNewSessionToken();
      }

      const addrString = (this.addressString || '').trim();
      this.addressOptions = [];

      if (this.lastScheduledSearch) {
        clearInterval(this.lastScheduledSearch);
        this.lastScheduledSearch = null;
      }

      if (addrString.length < 3) {
        this.searching = false;
        return;
      }

      this.searching = true;

      this.lastScheduledSearch = setTimeout(() => {
        AddressAPI.autoCompleteAddress(addrString, this.sessionToken).then(
          (result) => {
            const addresses = result.getData([]);
            if (addresses.length > 0) {
              this.addressOptions = addresses.map((a) => {
                return {
                  title: a.description,
                  data: a,
                };
              });
            } else {
              this.addressOptions = [{ title: 'Nenhum endereço encontrado.' }];
            }
            this.searching = false;
          }
        );
      }, 1000);
    },
    onAddressSelected(option, optionIndex, changeInput = true) {
      this.addressOptions = [];

      if (!option.data) {
        return;
      }

      if (option.data.id) {
        AddressAPI.addressDetail(option.data.id, this.sessionToken).then(
          (result) => {
            const address = result.getData(null);
            if (changeInput && address) {
              this.addressString = address.formattedAddress;
            }
            this.$emit('selectedAddress', address, option, optionIndex);
          }
        );
      }
    },
  },
};
</script>
